import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../air-distribut-system/airdistribution.css';
import Header from '../../../Components/Header/Header';
import { Link } from 'react-router-dom';
import product1 from '../../../images/products/air-distribution/1.1. TWIST OUTLET DD-N.jpg';
import product2 from '../../../images/products/air-distribution/1.2.1. RADIAL SLOT OUTLET RL.jpg';
import product3 from '../../../images/products/air-distribution/1.2.2. RADIAL SLOT OUTLET.jpg';
import product4 from '../../../images/products/air-distribution/1.3.1. RADIAL OUTLET.jpg';
import product5 from '../../../images/products/air-distribution/1.3.2. RADIAL OUTLET.jpg';
import product6 from '../../../images/products/air-distribution/1.3.3. RADIAL OUTLET.jpg';
import product7 from '../../../images/products/air-distribution/1.4.1. ADJUSTABLE RADIAL OUTLET.jpg';
import product8 from '../../../images/products/air-distribution/1.4.2. ADJUSTABLE RADIAL OUTLET WITH CORE TUBE.jpg';
import product9 from '../../../images/products/air-distribution/1.5. MICRODRALL MD.jpg';
import product10 from '../../../images/products/air-distribution/1.6.2. VARIABLE TWIST OUTLET WITH CORE TUBE.jpg';
import product11 from '../../../images/products/air-distribution/1.6.3. VARIABLE TWIST OUTLET WITH GUIDE RING.jpg';
import product12 from '../../../images/products/air-distribution/1.6.4. VARIABLE TWIST OUTLET WITH JET STRAIGHTENER.jpg';
import product13 from '../../../images/products/air-distribution/1.8. INDUCTION OUTLET WITH PRESET DISCHARGE DIRECTION.jpg';
import product14 from '../../../images/products/air-distribution/1.9. ADJUSTABLE INDUCTION OUTLET.jpg';
import product15 from '../../../images/products/air-distribution/1.10.1. OPTICLEAN.jpg';
import product16 from '../../../images/products/air-distribution/1.10.2. CIRCULAR OPTICLEAN.jpg';
import product17 from '../../../images/products/air-distribution/2.1.1. MULTIPLEX OUTLET.jpg';
import product18 from '../../../images/products/air-distribution/2.1.2. COMBINED MULTIPLEX OUTLET.jpg';
import product19 from '../../../images/products/air-distribution/2.2. LINEAR WHIRL OUTLET WL.jpg';
import product20 from '../../../images/products/air-distribution/2.3.1. SWIVEL JET NOZZLE.jpg';
import product21 from '../../../images/products/air-distribution/2.3.2. TWIST NOZZLE DW.jpg';
import product22 from '../../../images/products/air-distribution/2.3.3. JET NOZZLE.jpg';
import product23 from '../../../images/products/air-distribution/2.4. SWIVEL JET OUTLET.jpg';
import product24 from '../../../images/products/air-distribution/2.5. WINDOW AIR CURTAIN UNIT.jpg';
import product25 from '../../../images/products/air-distribution/2.6. BROAD MULTIPLEX OUTLET.jpg';
import product26 from '../../../images/products/air-distribution/2.7. PARAPET OUTLET.jpg';
import product27 from '../../../images/products/air-distribution/2.8. WALL SLOT DIFFUSER.jpg';
import product28 from '../../../images/products/air-distribution/2.9.1. AND 2.9.3. CROSSTALK ATTENUATION AIR TRANSFER ELEMENT.jpg';
import product29 from '../../../images/products/air-distribution/2.9.2 ACTIVE AIR TRANSFER ELEMENT A-SAVE WITH CROSSTALK ATTENUATOR.jpg';
import product30 from '../../../images/products/air-distribution/3.1. FLOOR TWIST OUTLET.jpg';
import product31 from '../../../images/products/air-distribution/3.2. ROTARY FLOOR TWIST OUTLET.jpg';
import product32 from '../../../images/products/air-distribution/3.3. ADJUSTABLE FLOOR OUTLET.jpg';
import product33 from '../../../images/products/air-distribution/3.4. NTK FLOOR TWIST OUTLET.jpg';
import product34 from '../../../images/products/air-distribution/3.6. FLOOR DISPLACEMENT OUTLET.jpg';
import product35 from '../../../images/products/air-distribution/3.7. FLOOR DISPLACEMENT OUTLET.jpg';
import product36 from '../../../images/products/air-distribution/4.1. RECTANGULAR DISPLACEMENT OUTLET.jpg';
import product37 from '../../../images/products/air-distribution/4.2.1. CIRCULAR DISPLACEMENT OUTLET.jpg';
import product38 from '../../../images/products/air-distribution/4.2.2. SEMI-CIRCULAR DISPLACEMENT OUTLET.jpg';
import product39 from '../../../images/products/air-distribution/4.5. WALL DISPLACEMENT OUTLET.jpg';
import product40 from '../../../images/products/air-distribution/4.6. CEILING DISPLACEMENT OUTLET.jpg';
import product41 from '../../../images/products/air-distribution/5.2.4. RECTANGULAR FLOOR DISPLACEMENT OUTLET.jpg';
import product42 from '../../../images/products/air-distribution/5.1. CIRCULAR DISPLACEMENT OUTLET.jpg';
import product43 from '../../../images/products/air-distribution/5.2. RECTANGULAR DISPLACEMENT OUTLET.jpg';
import product44 from '../../../images/products/air-distribution/5.3.1. TRAPEZOIDAL DISPLACEMENT OUTLET.jpg';
import product45 from '../../../images/products/air-distribution/5.3.2. SEMI-TRAPEZOIDAL DISPLACEMENT OUTLET.jpg';
import product46 from '../../../images/products/air-distribution/5.4. SWIVEL DISPLACEMENT OUTLET.jpg';
import product47 from '../../../images/products/air-distribution/5.5. RADIAL DISPLACEMENT OUTLET.jpg';
import product48 from '../../../images/products/air-distribution/5.6. CONICAL DISPLACEMENT OUTLET.jpg';
import product49 from '../../../images/products/air-distribution/5.7. LAMINAR OUTLET.jpg';
import product50 from '../../../images/products/air-distribution/5.8. DISPLACEMENT VENTILATION FOR INDOOR FIRING RANGES.jpg';
import product51 from '../../../images/products/air-distribution/6.2. SEAT DISPLACEMENT OUTLET.jpg';
import product52 from '../../../images/products/air-distribution/6.3. STEP DISPLACEMENT OUTLET.jpg';
import product53 from '../../../images/products/air-distribution/6.4.1. STEP TWIST OUTLET.jpg';
import product54 from '../../../images/products/air-distribution/6.4.2. STEP TWIST OUTLET.jpg';
import product55 from '../../../images/products/air-distribution/6.4.3. LINEAR STEP TWIST OUTLET.jpg';
import product56 from '../../../images/products/air-distribution/7.1. CIRCULAR VOLUME FLOW CONTROLLER.jpg';
import product57 from '../../../images/products/air-distribution/8.1 VENT AND DRAIN CAPS.jpg';
import product58 from '../../../images/products/air-distribution/1.1.1. STATIC COOLING CEILING SYSTEM.jpg';
import product59 from '../../../images/products/air-distribution/1.1.2. STATIC COOLING CEILING SYSTEM.jpg';
import product60 from '../../../images/products/air-distribution/1.2. STATIC COOLING CEILING SYSTEM.jpg';
import product61 from '../../../images/products/air-distribution/2.1.1. CONTACT COOLING CEILING SYSTEM.jpg';
import product62 from '../../../images/products/air-distribution/2.1.2 CONTACT COOLING CEILING SYSTEM.jpg';
import product63 from '../../../images/products/air-distribution/2.2. CONTACT COOLING CEILING SYSTEM.jpg';
import product64 from '../../../images/products/air-distribution/2.3. PLASTER COOLING CEILING SYSTEM PKS.jpg';
import product65 from '../../../images/products/air-distribution/3.1.1. MULTIFUNCTION EXPOXED CEILING AS RADIATION COOLING AND HEATING PANEL.jpg';
import product66 from '../../../images/products/air-distribution/3.1.2. MULTIFUNCTION EXPODED CEILING AVACS, SUPPLY AIR.jpg';
import product67 from '../../../images/products/air-distribution/3.1.3. MULTIFUNCTION EXPOSED CEILING AVACS.jpg';
import product68 from '../../../images/products/air-distribution/3.1.4. MULTIFUNCTION EXPOSED CEILING KRANTZCOOL.jpg';
import product69 from '../../../images/products/air-distribution/3.1.5. CHILLED SAILS MADE WITH HIGH-CAPACITY CEILING ELEMENTS.jpg';
import product70 from '../../../images/products/air-distribution/3.2. CHILLED SAILS MADE WITH GYPSUM BOARD.jpg';
import product71 from '../../../images/products/air-distribution/4.1. CHILLED BEAM, WITHOUT VENTILATION FUNCTION.jpg';
import product72 from '../../../images/products/air-distribution/4.2.1. CHILLED BEAM, WITH VENTILATION FUNCTION, TWO-SIDED DISCHARGE.jpg';
import product73 from '../../../images/products/air-distribution/4.2.2. CHILLED BEAM, WITH VENTILATION FUNCTION, ONE-SIDED DISCHARGE.jpg';
import product74 from '../../../images/products/air-distribution/4.5. CHILLED BEAM DK.jpg';
import product75 from '../../../images/products/air-distribution/4.5.2. CHILLED BEAM.jpg';
import product76 from '../../../images/products/air-distribution/5.1.3. VENTILATION UNIT.jpg';
import product77 from '../../../images/products/air-distribution/5.1.7. INDUCTION UNIT FOR MIXING VENTILATION.jpg';
import product78 from '../../../images/products/air-distribution/5.1.8. INDUCTION UNIT FOR DISPLACEMENT VENTILATION.jpg';
import product79 from '../../../images/products/air-distribution/5.2.1. VENTILATION UNITS FOR MIXING VENTILATION.jpg';
import product80 from '../../../images/products/air-distribution/5.2.2. VENTILATION UNIT.jpg';
import product81 from '../../../images/products/air-distribution/5.2.3. INDUCTION UNIT FOR MIXING VENTILATION.jpg';
import product82 from '../../../images/products/air-distribution/1.1.1. SAFE CHANGE FILTER HOUSING WITH SCANNER SCF HIGHTEC TRIPLE.jpg';
import product83 from '../../../images/products/air-distribution/1.1.2. SAFE CHANGE FILTER HOUSING WITH SCANNER SCF HIGHTEC.jpg';
import product84 from '../../../images/products/air-distribution/1.1.3. SAFE CHANGE FILTER HOUSING SCF CLASSIC.jpg';
import product85 from '../../../images/products/air-distribution/1.2. HEPA FILTER SYSTEM.jpg';
import product86 from '../../../images/products/air-distribution/1.3.1. AND 1.3.2. MOBILE FILTER SYSTEMS MFU CLASSIC AND MFU HIGHTEC.jpg';
import product87 from '../../../images/products/air-distribution/1.4.1. MOBILE RE-CLEANABLE FILTER SYSTEMS RHF HIGHTEC.jpg';
import product88 from '../../../images/products/air-distribution/1.4.2. RE-CLEANABLE FILTER SYSTEMS M-SCF CLASSIC.jpg';
import product89 from '../../../images/products/air-distribution/1.5.2. PURI-DRALL PDK, PURI-CLEAN PCK, PURI-INLET PEK.jpg';
import product90 from '../../../images/products/air-distribution/1.6.1. ADSORPTIONSFILTER CFH CLASSIC.jpg';
import product108 from '../../../images/products/air-distribution/1.6.2. ADSORPTIONSFILTER CFH HIGHTEC.jpg';
import product109 from '../../../images/products/air-distribution/1.8. SORPTION FILTER ELEMENT WFZ.jpg';
import product91 from '../../../images/products/air-distribution/2.1.1. GASTIGHT CIRCULAR DAMPER.jpg';
import product92 from '../../../images/products/air-distribution/2.1.2. GASTIGHT RECTANGULAR DAMPER.jpg';
import product93 from '../../../images/products/air-distribution/2.1.3. RECTANGULAR DAMPER S.jpg';
import product94 from '../../../images/products/air-distribution/2.2.1. PRESSURE RELIEF DAMPER FOR HIGH OPENING PRESSURES, WITH CONTROL FUNCTION.jpg';
import product95 from '../../../images/products/air-distribution/2.2.2. PRESSURE RELIEF DAMPERS FOR HIGH OPENING PRESSURES, WITH SWITCHING FUNCTION.jpg';
import product96 from '../../../images/products/air-distribution/2.2.3. PRESSURE RELIEF DAMPERS FOR LOW OPENING PRESSURES AT HIGH RANGE OF VOLUME FLOW.jpg';
import product97 from '../../../images/products/air-distribution/2.2.4. PRESSURE RELIEF DAMPERS FOR LOW OPENING PRESSURES AT LOW RANGE OF VOLUME FLOW.jpg';
import product98 from '../../../images/products/air-distribution/2.3.1. LOUVER DAMPER, AIRTIGHT DESIGN.jpg';
import product99 from '../../../images/products/air-distribution/2.3.2. LOUVER DAMPER, GASTIGHT DESIGN ND-J.jpg';
import product100 from '../../../images/products/air-distribution/2.4. NON-RETURN DAMPER RK-E20.jpg';
import product101 from '../../../images/products/air-distribution/2.5. PRESSURE SURGE DAMPER RK-F10.jpg';
import product102 from '../../../images/products/air-distribution/3.1.1. AND 3.1.2. HEPA FILTER ELEMENTS H13 AND H14.jpg';
import product103 from '../../../images/products/air-distribution/3.2.1. FIBRE FILTER F.jpg';
import product104 from '../../../images/products/air-distribution/3.2.2. FIBRE FILTER G.jpg';
import product105 from '../../../images/products/air-distribution/3.3. LEAK TEST DEVICE LT-D.jpg';
import product106 from '../../../images/products/air-distribution/3.8. DESICCANT DEHUMIDIFIER MDC.jpg';
import product107 from '../../../images/products/air-distribution/GASTIGHT DOORS GT.jpg';
import Footer from '../../../Components/Footer/Footer';
import ScrollToTopButton from '../../../Components/Scrolltotop/Scrolltotop';
import viewfile from '../../../images/view_file.png';
import ProductSection from '../ProductSection';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';

const animations = {
    shake: { x: [0, -10, 10, -10, 10, 0], transition: { duration: 0.5 } },
    pulse: { scale: [1, 1.1, 1], transition: { duration: 0.5 } },
    tada: { rotate: [0, 10, -10, 10, -10, 0], transition: { duration: 0.5 } },
    flash: { opacity: [1, 0, 1], transition: { duration: 0.5 } },
    bounce: { y: [0, -20, 0], transition: { duration: 0.5 } },
    swing: { rotate: [0, 10, -10, 10, -10, 0], transition: { duration: 0.5 } },
};

function AirDistribution() {
    const ceillingAirOutletsRef = useRef(null);
    const sidewallAirOutletsRef = useRef(null);
    const floorAirOutletsRef = useRef(null);
    const displacementOutletsCommercialRef = useRef(null);
    const displacementOutletsIndustrialRef = useRef(null);
    const airOutletsAssemblyRoomsRef = useRef(null);
    const volumeFlowControllerRef = useRef(null);
    const otherRef = useRef(null);
    const highCapacityCeilingSystemsRef = useRef(null);
    const metalGypsumBoardCeilingSystemsRef = useRef(null);
    const chilledSailsRef = useRef(null);
    const chilledBeamsRef = useRef(null);
    const facadeFloorMountedSystemsRef = useRef(null);
    const hepaFilterSystemsRef = useRef(null);
    const dampersRef = useRef(null);
    const accessoryMiscellaneousRef = useRef(null);

    const location = useLocation();
    const targetId = location.state?.targetId;

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const navbarHeight = document.querySelector('nav').offsetHeight;
            const offset = 20;
            window.scrollTo({
                top: element.offsetTop - navbarHeight - offset,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (targetId) {
            scrollToSection(targetId);
        }
    }, [targetId]);

    const scrollToRefSection = (ref) => {
        if (ref.current) {
            const navbarHeight = document.querySelector('nav').offsetHeight;
            window.scrollTo({
                top: ref.current.offsetTop - navbarHeight - 20,
                behavior: 'smooth',
            });
        }
    };


    const [popupData, setPopupData] = useState({ isVisible: false, image: '', title: '', content: '', animation: '' });

    const handleImageClick = (image, title, content, animation) => {
        setPopupData({ isVisible: true, image, title, content, animation });
        document.body.classList.add('popup-open');
    };

    const handleClosePopup = () => {
        setPopupData({ isVisible: false, image: '', title: '', content: '', animation: '' });
        document.body.classList.remove('popup-open');
    };

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('popup')) {
            handleClosePopup();
        }
    };

    const productContent1 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>11 - 265 l/s [40 - 950 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 100 up to DN 355</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.2 - 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Radial, horizontal jet dispersion</li>
                <li>Connection to flexible tube or spiral-seam duct via adapter or connection box</li>
                <li>Convenient screw fastener from below</li>
                <li>Perforated cover screen available on request</li>
                <li>Also available as return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.1_DD-N_Twist_outlet_06.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Twist Outlet DD-N</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.1_DD-N_Twist_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Twist Outlet DD-N</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent2 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation flush with ceiling or freely suspended.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>12.5 –280 l/s [45 –1 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Sizes</td>
                    <td className='td-2'>300 – 600</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>With square face</li>
                <li>With square or circular bar array</li>
                <li>Radial jet dispersion</li>
                <li>Various bar segments can be closed, thus enabling asymmetric jet Dispersion</li>
                <li>Convenient screw fastener from below</li>
                <li>Also available as return air inlet with/without bars</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.2.1_RL_Radial_slot_outlet_09.2014-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Radial slot outlet RL RL-Q2 und RL-R2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.2.1_RL_Radial_slot_outlet_kr (1).pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Radial slot outlet RL RL-Q2 und RL-R2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent3 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>22 – 320 l/s [80 – 1 150 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>375, 470, 600 and 750</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Discharge direction manually adjustable from horizontal to downward incline</li>
                <li>Circular air outlet element with circular connection box for exposed mounting or with cubic connection box for installation flush with ceiling</li>
                <li>With circular bar array</li>
                <li>Radial jet dispersion</li>
                <li>Individual bars can be closed thus enabling asymmetric jet dispersion</li>
                <li>Easy screw fastening from below</li>
                <li>Also available as return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.2.2_RL-C_Radial_slot_outlet_01.2018-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Radial slot outlet RL-C</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.2.2_RL-C_Radial_slot_outlet_kr (2).pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Radial slot outlet RL-C</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent4 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>11 – 555 l/s [40 – 2 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 100 to DN 500</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.2 - 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Radial, horizontal jet dispersion</li>
                <li>With circular or square face</li>
                <li>Low height</li>
                <li>Connection to flexible tube or spiral-seam duct via adapter or connection box</li>
                <li>Convenient screw fastener from below</li>
                <li>Also available as return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.3.1_RA-N_Radial_outlet_02.2016_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Radial outlet RA-N</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.3.1_RA-N_Radial_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Radial outlet RA-N</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent5 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>28 – 395 l/s [100 – 1 420 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 250 to DN 500</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 - 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Merkmale</p>
            <ul className='list'>
                <li>Radial, horizontal jet dispersion; therefore high level of thermal comfort</li>
                <li>With circular or square visible surface</li>
                <li>Low height</li>
                <li>Connection to flexible or spiral-seam duct via reducer or connection box</li>
                <li>Easy screw fastening from below</li>
                <li>Can also be used as a return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.3.2_RA-N2_Radial_outlet_04.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Radial outlet RA-N2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.3.2_RA-N2_Radial_outlet_kr (1).pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Radial outlet RA-N2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent6 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or free-hanging.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>26 – 400 l/s [95 – 1 440 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 355 and DN 500</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.4 - 4.5 m</td>
                </tr>
                <tr>
                    <td className='td'>Temperature differential</td>
                    <td className='td-2'>-12 K to + 5 K<br />
                        (+ 10 K up to 3 m ceiling height)</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Radial, horizontal jet dispersion; therefore high level of thermal comfort</li>
                <li>Available in 2 sizes, with square or circular face</li>
                <li>High volume flow rate per size</li>
                <li>Low height</li>
                <li>Connection to flexible or spiral-seam duct via reducer or connection box</li>
                <li>Easy screw fastening from below</li>
                <li>Can also be used as a return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.3.3_RA-N3_Radial_outlet_04.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Radial outlet RA-N3</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.3.3_RA-N3_Radial_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Radial outlet RA-N3</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E_TB69_layout-specifications-for-thermal-comfort.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent7 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding in high halls, particularly suitable for large thermal load fluctuations.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>61 - 1 530 l/s [220 – 5 500 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 200 – DN 500</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 13 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Stepless discharge direction adjustment from horizontal to vertical, manually or with electric servomotor</li>
                <li>Radial jet dispersion</li>
                <li>Shorter heating-up period with vertical discharge direction</li>
                <li>Connection to spiral seam duct or connection box</li>
                <li>Low height</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.4.1_RA-V_Adjustable_radial_outlet_04.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Adjustable radial outlet RA-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.4.1_RA-V_Adjustable_radial_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Adjustable radial outlet RA-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent8 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or freely suspended in industrial or commercial applications; also suitable for gread room heights. Continuous air jet adjustment for cooling and heating.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>111 – 4 444 l/s [400 – 16 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 250 – DN 900</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.8 – 15 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Discharge direction adjustable from horizontal to vertical (downwards)</li>
                <li>Adjustment of discharge direction via thermostatic control unit, electric actuator, or manual adjusting device</li>
                <li>With circular or square face</li>
                <li>Radial jet spread in cooling mode</li>
                <li>Shorter heating-up time due to vertical discharge in heating mode</li>
                <li>Connection directly to circular ducts to EN 1506 or via connection box</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.4.2_RA-V2_Adjustable_radial_outlet_with_core_tube_01.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Adjustable radial outlet with core tube RA-V2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.4.2_RA-V2_Adjustable_radial_outlet_with_core_tube_kr (1).pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Adjustable radial outlet with core tube RA-V2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent9 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation flush with ceiling or freely suspended.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>61 – 208 l/s [220 –750 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Sizes</td>
                    <td className='td-2'>600 / 625</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 - 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>With square face</li>
                <li>With square or circular bar array</li>
                <li>Radial jet dispersion</li>
                <li>With cubic connection box</li>
                <li>Convenient screw fastener from below</li>
                <li>Used as return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.5_MD_Microdrall_05.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Microdrall MD</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.5_MD_Microdrall_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Microdrall MD</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent10 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding in high halls, particularly suitable for large thermal load fluctuations.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>125 – 3 050 l/s [450 – 11 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 315, DN 400, DN 600 and DN 710</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>3 – 15 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Adjustable discharge direction from horizontal to vertical, manually or with servomotor</li>
                <li>Radial jet dispersion</li>
                <li>Shorter heating-up period with vertical discharge direction</li>
                <li>Connection to spiral-seam duct or connection box</li>
                <li>Available with curved intake to meet high acoustic requirements</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.6.2_DD-VK_Variable_twist_outlet_with_core_tube_05.2007-wTC_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Variable twist outlet with core tube DD-VK</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.6.2_DD-VK_Variable_twist_outlet_with_core_tube_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Variable twist outlet with core tube DD-VK</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent11 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding in high halls, particularly suitable for large thermal load fluctuations and high acoustic requirements.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>170 – 3 055 l/s [600 – 11 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 315, DN 400, DN 500, DN 630 and DN 710</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>3 – 12 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Adjustable discharge direction from horizontal to vertical, manually or with servomotor</li>
                <li>Radial jet dispersion</li>
                <li>Shorter heating-up period with vertical discharge direction</li>
                <li>Connection to spiral-seam duct or connection box</li>
                <li>Low sound power level</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.6.3_DD-VL_Variable_twist_outlet-guide_ring_01.2018-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Variable twist outlet with guide ring DD-VL</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.6.3_DD-VL_Variable_twist_outlet_with_guide_ring_2018_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Variable twist outlet with guide ring DD-VL</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent12 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling or downstanding in very high halls, particulary suitable for large thermal load fluctuations combined with a high temperature difference between supply air and indoor air when heating.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>170 – 3 055 l/s [600 – 11 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 315, DN 400, DN 500, DN 630 and DN 710</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>3 – 28 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Adjustable discharge direction from horizontal to vertical, manually or with servomotor</li>
                <li>Radial jet dispersion</li>
                <li>Shorter heating-up period with vertical discharge direction</li>
                <li>Connection to spiral-seam duct or connection box</li>
                <li>Same construction as DD-VL, but in addition with jet straightener</li>
                <li>Very large penetration depth when heating</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.6.4_DD-VG_Variable_twist_outlet-jet_streigtener_01.2018-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Variable twist outlet with jet straigthener DD-VG</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.6.4_DD-VG_Variable_twist_outlet_with_jet_straightener_2018_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Variable twist outlet with jet straigthener DD-VG</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent13 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>28 – 85 l/(s•m) [100 – 300 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Element width</td>
                    <td className='td-2'>90 mm</td>
                </tr>
                <tr>
                    <td className='td'>Standard lengths</td>
                    <td className='td-2'>1; 1.2 and 1.6 m</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>4 – 7 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Stable single jets with alternating inclined discharge, or one-sided single jets at half the volume flow rate, for all ceilings</li>
                <li>Fixed discharge angle of 45° to horizontal</li>
                <li>With connection box and circular connection spigot</li>
                <li>Momentum control device manually adjustable for optimum adjustment of jet momentum to design volume flow rate, or for one-sided air discharge at half volume flow rate</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.8_IN-N6_Induction_outlet_with_preset_discharge_direction_04.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Induction outlet with preset discharge direction IN-N6</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.8_IN-N_Induction_outlet_with_preset_discharge_direction_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Induction outlet with preset discharge direction IN-N6</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent14 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation flush with ceiling and where manual adjustment of discharge direction is required.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>2.8 – 110 l/(s•m) [10 – 400 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Element width</td>
                    <td className='td-2'>15 or 28 mm</td>
                </tr>
                <tr>
                    <td className='td'>Lengths</td>
                    <td className='td-2'>1 050; 1 200; 1 350 und 1 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Stable single jets with alternate or one-sided discharge, for all ceilings</li>
                <li>Discharge direction adjustment from horizontal to nearly vertical</li>
                <li>Type IN-V2: element width: 28 mm per row; also available in 2, 3 or 4 rows</li>
                <li>Tpye IN-V3: element width: 15 mm; 1 row</li>
                <li>With connection box and circular connection spigot</li>
                <li>Also available as return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.9_IN-V_Adjustable_induction_outlet_04.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Adjustable induction outlet IN-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.9_IN-V_Adjustable_induction_outlet_kr (1).pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Adjustable induction outlet IN-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent15 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation in suspended ceiling systems.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>11 – 239 l/s [40 – 860 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Sizes</td>
                    <td className='td-2'>215, 270, 330, 400, 500, 600, 625</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Radial, horizontal jet spread at high level of thermal comfort</li>
                <li>Perforated square face, grid dimensions: 600 x 600 mm and 625 x 625 mm</li>
                <li>Square design for integration into gypsum board ceilings</li>
                <li>Very uniform air discharge; as a result, no or extremely low dirt accumulation on the ceiling</li>
                <li>Segment covers enable to adapt the supply air distribution to the room geometry, e.g. narrow corridor with 180° discharge</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E_1.10.1_K250e_Opticlean_Metal_ceiling_12.2018.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Opticlean for installation in metal ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.10.1_OC-Q_Opticlean_06.2013-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Opticlean OC-Q</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.10.1_OC-Q_Opticlean_kr (1).pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender tender - Opticlean OC-Q</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent16 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation in suspended ceiling systems.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>25 – 169 l/s [90 – 610 m³/h ]</td>
                </tr>
                <tr>
                    <td className='td'>Sizes</td>
                    <td className='td-2'>300 and 500</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 4.5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Steady radial jet spread resulting in high thermal comfort</li>
                <li>Perforated circular faceplate, hole diameter 3 mm</li>
                <li>Strong reduction of dirt accumulation on the ceiling thanks to very even air distribution and the resulting air cushion</li>
                <li>Unobtrusive integration into suspended ceilings</li>
                <li>Also usable as return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.0_K189e_Ceiling_air_outlets_2005-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Ceiling Air Outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.10.2_OC-R_Circular_Opticlean_01.2018-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Circular Opticlean OC-R</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.1.10.2_OC-R_Circular_Opticlean_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Circular Opticlean OC-R</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent17 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation in corridor walls of offices, meeting rooms, etc., to provide the occupied zone with supply air spread out into thin single jets.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 40 l/(s•m) [145 m³/(h•m)] –1-row design <br />≤ 50 l/(s•m) [185 m³/(h•m)] – 2-row design</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>600, 800 and 1000</td>
                </tr>
                <tr>
                    <td className='td'>Height of air outlet</td>
                    <td className='td-2'>140 mm</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 4 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Bundles of thin, free single jets</li>
                <li>Jet bundle elements manually rotatable through 360°</li>
                <li>Pronounced spread of supply air jets</li>
                <li>Rapid decrease in jet velocity and temperature difference</li>
                <li>Single-row or double-row arrangement of jet bundle elements</li>
                <li>With connection box for flexible duct connection</li>
                <li>Also usable as return air inlet</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Multiplex outlet FA-VT</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.1.1_FA-V_Multiplex_outlet_kr (1).pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Multiplex outlet FA-VT</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent18 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation in corridor walls of offices, meeting rooms, etc., to discharge supply air in thin single jets from the lower outlet segment into the occupied zone and to remove return air through the upper outlet segment​.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 40 l/(s•m) [145 m³/(h•m)] for supply air and return air respectively</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>600, 800 and 1000</td>
                </tr>
                <tr>
                    <td className='td'>Height of air outlet</td>
                    <td className='td-2'>260 mm</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 4 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Combined supply air and return air outlet</li>
                <li>Generation of jet bundles with thin, free, single supply air jets</li>
                <li>Jet bundle elements manually rotatable through 360°</li>
                <li>Pronounced spread of supply air jets</li>
                <li>Rapid decrease in jet velocity and temperature difference</li>
                <li>Jet bundle elements for supply air and return air respectively arranged in one row</li>
                <li>Return air segment also without jet bundle elements; return air intake via perforated faceplate</li>
                <li>Connection box with supply air and return air spigots for flexible duct connection</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Combined multiplex outlet FA-VT-K</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.1.2_FA-VK_Combined_multiplex_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Combined multiplex outlet FA-VT-K</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent19 = (
        <div className="product-content">
            <h3 className='popup_title'>For mounting on walls or galleries, with nearly horizontal discharge direction, where penetration depths of 4 to 16 m are required.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>28 – 300 l/s [100 – 1 100 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>30, 45 and 65 mm (discharge chamber height)</td>
                </tr>
                <tr>
                    <td className='td'>Penetration depth</td>
                    <td className='td-2'>4 – 16 m</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.6 – 6 m</td>
                </tr>
                <tr>
                    <td className='td'>Standard lengths	</td>
                    <td className='td-2'>1 - 1.1 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Linear free jet consisting of 5 to 7 single jets, depending on type</li>
                <li>Discharge direction nearly horizontal</li>
                <li>Low sound power level</li>
                <li>3 sizes for different penetration depths</li>
                <li>Also available with low height for low ceiling plenums</li>
                <li>Connection box for flexible duct connection, or direct connection to main air duct</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.2.2_WL_Linear_whirl_outlet_12.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Linear whirl outlet WL</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.2_WL_Linear_whirl_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Linear whirl outlet WL</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent20 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation on walls, pillars or galleries, where adjustment of discharge direction with servomotor or by hand is required.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>11 – 589 l/s [40 – 2 120 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 60 – DN 250</td>
                </tr>
                <tr>
                    <td className='td'>Throw</td>
                    <td className='td-2'>3 - 50 m</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 10 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Circular free jet</li>
                <li>Discharge direction adjustable through ± 30° around the swivel axis</li>
                <li>Adjustment options: manually, by electric actuator or, without auxiliary energy, by maintenance-free thermostatic control unit</li>
                <li>The thermostatic control unit allows for resetting; the swivel range can be altered in increments of 5°, up to 20˚ in total.</li>
                <li>Position of swivel axis also adjustable in the vertical plane, enabling to alter the discharge direction sideways</li>
                <li>Low sound power level</li>
                <li>Low pressure drop</li>
                <li>Direct connection to supply air duct or pressurized chamber, or connection with push-in end for spiral seam duct and with slip-on end for shaped parts</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.2.3.1_DW-V2_Swivel_jet_nozzle_01.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection -Swivel jet nozzle DW-V2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.3.1_DW-V2_Swivel_jet_nozzle_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Swivel jet nozzle DW-V2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent21 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation on walls, pillars or galleries, where adjustment of discharge direction with servomotor or by hand is required.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 395 l/s [1 420 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 80 - DN 250</td>
                </tr>
                <tr>
                    <td className='td'>Throw</td>
                    <td className='td-2'>1 – 17 m</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.8 - 10 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Twist nozzle with small jet penetration depth for air distribution into narrow spaces, with built-on twist element</li>
                <li>Circular free jet</li>
                <li>Discharge direction adjustable through ± 30° around the swivel axis</li>
                <li>Adjustment options: manually, by electric actuator or, without auxiliary energy, by maintenance-free thermostatic control unit</li>
                <li>The thermostatic control unit allows for resetting; the swivel range can be altered in increments of 5°, up to 20˚ in total.</li>
                <li>Position of swivel axis also adjustable in the vertical plane, enabling to alter the discharge direction sideways</li>
                <li>Low sound power level</li>
                <li>Low pressure drop</li>
                <li>Direct connection to supply air duct or pressurized chamber, or connection with push-in end for spiral seam duct and with slip-on end for shaped parts</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Twist nozzle DW-V2-DR</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.3.2_DW-V2-_-DR_Twist_nozzle_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Twist nozzle DW-V2-DR</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent22 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation on walls, pillars or galleries, particularly suitable for rooms with very high acoustic requirements and where the requisite discharge angle can be predetermined.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>11 – 589 l/s [40 – 2 120 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 60 to DN 250</td>
                </tr>
                <tr>
                    <td className='td'>Throw</td>
                    <td className='td-2'>3 – 50 m</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.5 – 10 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Circular free jet</li>
                <li>Fixed discharge direction</li>
                <li>Extremely low sound power level</li>
                <li>Very low pressure drop</li>
                <li>Direct connection to supply air duct or pressurized chamber, or connection with push-in end for spiral seam duct or with slip-on end for shaped parts</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Jet nozzle DW-N2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.3.3_DW-N2_Jet_nozzle_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Jet nozzle DW-N2</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent23 = (
        <div className="product-content">
            <h3 className='popup_title'>For mounting on walls, pillars or galleries, where large penetration depths and high volume flow rates per air outlet are required</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>110 – 2 800 l/s [400 – 10 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 315, DN 400, DN 600 and DN 710</td>
                </tr>
                <tr>
                    <td className='td'>Penetration depth</td>
                    <td className='td-2'>up to 30 m</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>4 – 6 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Circular, high-turbulence free jet</li>
                <li>Discharge direction adjustable in a plane by ± 20° to the air outlet axis, manually or with servomotor</li>
                <li>Penetration depth adjustable by opening or closing the core tube</li>
                <li>Optional ring insert for very large penetration depths</li>
                <li>Connection to side of main supply air duct or with connection box</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Swivel jet outlet SW</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.4_SW_Swivel_jet_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Swivel jet outlet SW</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent24 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in window sills for upward-directed air curtain to compensate for heat transmission by the window when cooling and heating.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>8 – 25 l/(s•m) [30 - 90 m³/(h•m)] *</td>
                </tr>
                <tr>
                    <td className='td'>Slot width</td>
                    <td className='td-2'>3 – 10 mm</td>
                </tr>
                <tr>
                    <td className='td'>Standard lengths</td>
                    <td className='td-2'>1; 1.2; 1.4 and 1.6 m</td>
                </tr>
                <tr>
                    <td className='td'>Penetration height</td>
                    <td className='td-2'>2 – 10 m</td>
                </tr>
            </table>
            <p className='td'>* higher volume flow rates on request</p>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Linear air curtain</li>
                <li>Installation in floor or window sill, along the facade</li>
                <li>Max. distance to glass pane: 200 mm</li>
                <li>Small space required (width 55 mm, height 260 to 310 mm)</li>
                <li>With connection box for flexible duct Connection</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.2.5_FSG_Window_air_curtain_unit_04.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Window air curtain unit FSG</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.5_FSG_Window_air_curtain_unit_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Window air curtain unit FSG</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent25 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in the upper area of sidewalls, e.g. in hotel rooms, for spread-out supply air jets and draught-free air distribution.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>22 – 150 l/s [80 – 540 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>600, 800 and 1 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Designs</td>
                    <td className='td-2'>1-row and 2-row design</td>
                </tr>
                <tr>
                    <td className='td'>Mounting height</td>
                    <td className='td-2'>2.2 – 4 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Sidewall air outlet fulfilling the high thermal comfort criteria for commercial applications to EN ISO 7730</li>
                <li>Perforated front plate with built-in nozzle discs in 1-row or 2-row design</li>
                <li>Optionally with perforated design faceplate</li>
                <li>Combined mixing/displacement ventilation system ensuring a high ventilation efficiency in the occupied zone</li>
                <li>The air jets can be spread out as broadly as desired by manually rotating individual nozzle discs by up to 360°</li>
                <li>The broad multiplex outlet may be positioned in the middle or on a side of the room wall (so-called symmetric or asymmetric Arrangement)</li>
                <li>Maximum temperature difference between supply and indoor air ± 10 K</li>
                <li>Low sound power level and low pressure drop, thus well suited for connection to fan coils</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Broad multiplex outlet BF-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EZ1.2.6_BF-V_Broad_multiplex_outlet_01.2012-1-ACCESSORY_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Accessory - Broad multiplex outlet BF-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.6_BF-V_Broad_multiplex_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Broad multiplex outlet BF-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent26 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in window parapets above existing fan coil units. For facade screening when cooling and heating; it also enables the ventilation of workplaces next to the facade.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 200 l/s [720 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>800, 1 025, 1 250 and 1 550 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>202 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Parapet outlet consisting of a double-row, adjustable induction outlet and a multiplex outlet</li>
                <li>Vertical jet for facade screening and - where required - broadly spread air jet inclined to room for individual workplace ventilation</li>
                <li>No tangential air patterns causing thermal discomfort</li>
                <li>Ideal for replacing simple supply air grilles in window parapets to improve indoor air flow</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection -Parapet outlet BL-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.7_BL-V_Parapet_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Parapet outlet BL-V</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent27 = (
        <div className="product-content">
            <h3 className='popup_title'>For installation in gypsum plasterboard walls.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 66.5 l/s [240 m³/h] per metre of diffuser length</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>525, 1 050 and 1 125 mm</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>2.4 – 3.5 m</td>
                </tr>
            </table>
            <p className='title'>References</p>
            <ul className='list'>
                <li>With 1 or 2 rows for supply air or return air or both combined</li>
                <li>Slot element easy to remove thanks to push-in connection; thus cleanable as per VDI 6022</li>
                <li>Connection box to be installed inside or behind gypsum plasterboard walls; volume flow damper adjustable from room (optional)</li>
                <li>High level of thermal comfort: max. cooling capacity up to 120 W/m²</li>
                <li>High insertion loss with abrasion-resistant acoustic lining of class A2 as per DIN 4102-1 (optional); this obviates the need for crosstalk silencers</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.2.8_WSD_Wall_slot_diffuser_12.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Wall slot diffuser WSD</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.8_WSD_Wall_slot_diffuser_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Wall slot diffuser WSD</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent28 = (
        <div className="product-content">
            <h3 className='popup_title'>For use in administrative and office buildings, for Installation: OG-S and OG-T in plasterboard walls, OG-U under the ceiling</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <th>Type</th>
                    <th>OG-S and OG-T</th>
                    <th>OG-U</th>
                </tr>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>S-shape:
                        approx. 53 l/(s•m)
                        [190 m³/(h•m)]
                        T-shape:
                        approx. 47 l/(s•m)
                        [170 m³/(h•m)] at a
                        pressure drop of 15 Pa</td>
                    <td className='td-2'>25 l/s [90 m3/h] at a pressure drop of 6 Pa 35 l/s [126 m3/h] at a pressure drop of 10 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>500 and 1 000</td>
                    <td className='td-2'>800 and 1 000</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>400 mm</td>
                    <td className='td-2'>45 mm</td>
                </tr>
                <tr>
                    <td className='td'>Wall thickness</td>
                    <td className='td-2'>S-shape: 100 mm
                        T-shape: 125 mm</td>
                    <td className='td-2'>Installation under the ceiling</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>With built-in silencer for reducing noise transmission from one room to the other (crosstalk attenuator)</li>
                <li>High level of sound absorption at low pressure drop</li>
                <li>High-quality design</li>
                <li>The acoustic lining is of fire resistance class B1 to DIN 4102-1</li>
                <li>Easy to install</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Crosstalk attenuation air transfer element OG</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.2.9_OG_Crosstalk-attenuation-air-transfer-element_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Crosstalk attenuation air transfer element OG</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Crosstalk attenuation air transfer element OG-U for mounting on ceiling</p></div>
            </div>
        </div>
    );
    const productContent29 = (
        <div className="product-content">
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>17 l/s [60 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>872 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>400 mm</td>
                </tr>
                <tr>
                    <td className='td'>Wall thickness</td>
                    <td className='td-2'>130, 195 and 260 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>For silent and energy-efficient ventilation of dwellings and offices</li>
                <li>With built-in crosstalk attenuator for reducing room-to-room sound transmission</li>
                <li>Acoustic lining of fire resistance class B1 to DIN 4102-1</li>
                <li>Quick and simple installation</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Sidewall air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent30 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in raised floors; air supply from below with high-turbulence vertical flow and large induction effect in floor zone.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>5.5 – 50 l/s [20 – 180 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 150 and DN 200</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>High-induction, rotationally symmetric, stable vertical Jet </li>
                <li>For insertion in a stepped bore or installation with a clamp insert in a through bore of the floor tile</li>
                <li>DN 150: Twist element and clamp insert available in plastic</li>
                <li>DN 200: Twist element and clamp insert available in plastic and aluminium</li>
                <li>Depending on type: Air outlet element fitted with lock against unauthorized removal</li>
                <li>Suitable for air connection to the 'pressurized plenum' system, or with connection box for duct connection</li>
                <li>With distributor basket for even air supply; also available with throttle device (adjustable from room) for volume flow rate adjustment</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Floor air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.3.1_DB-E-DN_Floor_twist_outlet_05.2015-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Floor twist outlet DB-E</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.3.1_DB-E-DN_Floor_twist_outlets_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Floor twist outlet DB-E</p></div>
            </div>
        </div>
    );
    const productContent31 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in raised floors; air supply from below with large induction effect in floor zone, with inclined air jet axis.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>5.5 – 50 l/s [20 – 180 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 125 and DN 200</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>High-induction air jet with jet axis incline of 30° to vertical, rotatable for individual adjustment of air velocities at head level seated person</li>
                <li>For installation with a clamp insert in a through bore of the floor tile; DN 200 also design for insertion in a stepped bore</li>
                <li>Twist element and clamp insert available in plastic and aluminium</li>
                <li>Depending on type: Air outlet element fitted with lock against unauthorized removal</li>
                <li>Suitable for air connection to the 'pressurized plenum' system, or with connection box for duct connection</li>
                <li>With distributor basket for even air supply; also available with throttle device for volume flow rate adjustment</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Floor air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.3.2_DB-D-DN_Rotary_floor_twist_outlet_05.2015_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Rotary floor twist outlet DB-D</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.3.2_DB-D-DN_Rotary_floor_twist_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Rotary floor twist outlet DB-D</p></div>
            </div>
        </div>
    );
    const productContent32 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in raised floors; air supply from below, with adjusting disc to change the discharge pattern from 'floor twist outlet' with high-turbulence vertical flow to 'floor displacement outlet' with low-turbulence, radial horizontal flow.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>5.5 – 14 l/s [20 – 50 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 150</td>
                </tr>
            </table>
            <p className='title'>Merkmale</p>
            <ul className='list'>
                <li>Operation as floor twist outlet or floor displacement outlet</li>
                <li>For insertion in a stepped bore or installation with a clamp insert in a through bore of the floor tile</li>
                <li>Twist element and clamp insert made of plastic</li>
                <li>Suitable for air connection to the 'pressurized plenum' system, or with connection box for duct connection</li>
                <li>With distributor basket for even air supply; also available with throttle device for volume flow rate Adjustment </li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Floor air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.3.3_BA-V-DN150_Adjustable_floor_outlet_05.2015_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Adjustable floor outlet BA-V-DN 150</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.3.3_BA-V-DN150_Adjustable_floor_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Adjustable floor outlet BA-V-DN 150</p></div>
            </div>
        </div>
    );
    const productContent33 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in floor plenums; air supply from below, with vertical jet axis.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>8.5 – 16.5 l/s [30 – 60 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 215</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Slim vertical jet with intensive indoor air admixture</li>
                <li>For low floor plenums, installation with floor insert in floor orifices</li>
                <li>Twist element and clamp insert made of plastic</li>
                <li>For air connection to the 'pressurized plenum' System</li>
                <li>Floor insert with perforated throttle disk</li>
                <li>Available on request with central recess to accommodate floor covering</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Floor air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - NTK floor twist outlet DB-N-DN 215</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.3.4_DB-N_NTK-floor_twist_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - NTK floor twist outlet DB-N-DN 215</p></div>
            </div>
        </div>
    );
    const productContent34 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in raised floors; air supply from below, to generate low-turbulence, radial horizontal flow for displacement ventilation.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 28 l/s [100 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 200</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Radial, horizontal jet dispersion</li>
                <li>For insertion in a stepped bore or installation with a clamp insert in a through bore of the floor tile</li>
                <li>Twist element and clamp insert made of aluminium</li>
                <li>For air connection to the 'pressurized plenum' system, or with connection box for duct connection</li>
                <li>With distributor basket for even air supply; also available with throttle device for volume flow adjustment </li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Floor air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.3.6_Q-B-DN200_Floor_displacement_outlet_05.2015_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Floor displacement outlet Q-B-DN 200</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.3.6_Q-B-DN200_Floor_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Floor displacement outlet Q-B-DN 200</p></div>
            </div>
        </div>
    );
    const productContent35 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in floor plenums; air supply from below, to generate low-turbulence, radial horizontal flow for displacement ventilation.​​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>5.5 – 14 l/s [20 –50 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 215</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Radial, horizontal jet dispersion</li>
                <li>For low floor plenums, installation with floor insert in floor orifice</li>
                <li>Twist element and clamp insert made of plastic</li>
                <li>For air connection to the 'pressurized plenum' system</li>
                <li>Floor insert with perforated throttle disk</li>
                <li>Available on request with central recess to accommodate floor covering</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Floor air outlets Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Floor displacement outlet Q-B-DN 215</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.3.7_Q-B-DN215_Floor_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Floor displacement outlet Q-B-DN 215</p></div>
            </div>
        </div>
    );
    const productContent36 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation on the floor, in front of a wall, parapet or pillar, or free-standing in room, or integration into room furniture.​​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 445 l/s [1 600 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>≤ 0.25 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Coverage</td>
                    <td className='td-2'>5 – 15 m</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 30 dB(A) ref. 10-12W</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Even, low-turbulence and draught-free discharge flow</li>
                <li>Single installation or in rows side by side</li>
                <li>Easy to integrate into furniture</li>
                <li>Connection from above or below</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Displacement air outlets commercial sector Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.4.1_Q-R_Rectangular_displacement_outlet_05.2012_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Rectangular displacement outlet Q-R</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.4.1_Q-R_Rectangular_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Rectangular displacement outlet Q-R</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.4.0.2_General_layout_specifications_04.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>General layout specifications for Displacement ventilation for commercial</p></div>
            </div>
        </div>
    );
    const productContent37 = (
        <div className="product-content">
            <h3 className='popup_title'>For free-standing placement in room.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 720 l/s [2 600 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>≤ 0.25 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Coverage zone</td>
                    <td className='td-2'>5 – 10 m</td>
                </tr>
                <tr>
                    <td className='td'>Sound power Level</td>
                    <td className='td-2'>≤ 35 dB(A) ref. 10-12 W</td>
                </tr>
                <tr>
                    <td className='td'>Diameter</td>
                    <td className='td-2'>200 to 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>400 to 1 500 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Even, horizontal, low-turbulence discharge flow</li>
                <li>Connection from above or below, depending on type</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Displacement air outlets commercial sector Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Circular displacement outlet Q-Z</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.4.2.1_Q-Z_Circular_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Circular displacement outlet Q-Z</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.4.0.2_General_layout_specifications_04.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>General Information about displacement air outlets</p></div>
            </div>
        </div>
    );
    const productContent38 = (
        <div className="product-content">
            <h3 className='popup_title'>For mounting on walls or pillars.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 360 l/s [1 300 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>≤ 0.25 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Coverage</td>
                    <td className='td-2'>5 – 10 m</td>
                </tr>
                <tr>
                    <td className='td'>Sound power Level</td>
                    <td className='td-2'>≤ 35 dB(A) ref. 10-12 W</td>
                </tr>
                <tr>
                    <td className='td'>Diameter</td>
                    <td className='td-2'>125 – 315 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>400 – 1 500 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Even, horizontal, low-turbulence discharge flow</li>
                <li>Connection from above or below, depending on type</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Displacement air outlets commercial sector Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Semi-circular displacement outlet Q-ZH</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.4.2.2_Q-ZH_Semi-circular_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Semi-circular displacement outlet Q-ZH</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>General Information about displacement air outlets</p></div>
            </div>
        </div>
    );
    const productContent39 = (
        <div className="product-content">
            <h3 className='popup_title'>Installation in upper section of room wall (ideally corridor wall) below ceiling.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Type Q-WL</td>
                    <td className='td'>Linear wall displacement outlet</td>
                </tr>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 28 l/(s•m) [100 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>≤ 0.19 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Sound power Level</td>
                    <td className='td-2'>≤ 33 dB(A) ref. 10-12 W</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>180 mm</td>
                </tr>
                <tr>
                    <td className='td'>Standard length</td>
                    <td className='td-2'>0.6, 0.8, 1.0 and 1.2 m</td>
                </tr>
                <tr>
                    <td className='td'>Type Q-WR</td>
                    <td className='td'>Circular wall displacement outlet</td>
                </tr>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 36 l/s [130 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>≤1 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 34 dB(A) ref. 10-12 W</td>
                </tr>
                <tr>
                    <td className='td'>Nominal diameter</td>
                    <td className='td-2'>DN 80, DN 100 and DN 125</td>
                </tr>
                <tr>
                    <td className='td'>Outside diameter</td>
                    <td className='td-2'>190, 220 and 260 mm</td>
                </tr>
                <tr>
                    <td className='td'>Type Q-WK</td>
                    <td className='td'>Combined wall displacement outlet</td>
                </tr>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 28 l/(s•m) [100 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>≤ 0.19 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 32 dB(A) ref. 10-12 W</td>
                </tr>
                <tr>
                    <td className='td'>Standard length</td>
                    <td className='td-2'>0.6; 0.8 und 1.0 m</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>280 mm</td>
                </tr>
                <tr>
                    <td className='td'>Standard length</td>
                    <td className='td-2'>1.2 m</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>310 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Installation height 2 to 4 m</li>
                <li>Jet pattern downwards along the wall, then over the floor and finally vertical upflow through the occupied zone to the ceiling zone</li>
                <li>Minimum supply air temperature 16 °C</li>
                <li>Minimum distance to ceiling 120 mm</li>
                <li>Connection from behind</li>
                <li>Type <span style={{ fontWeight: "bold" }}>Q-WL</span> - Rectangular outlet</li>
                <li>Type <span style={{ fontWeight: "bold" }}>Q-WR</span> - Circular outlet</li>
                <li>Type <span style={{ fontWeight: "bold" }}>Q-WK</span> - Combined outlet with supply air and return air segment</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Displacement air outlets commercial sector Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Wall displacement outlet Q-WL, Q-WR and Q-WK</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.4.5_Q-W__Wall_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Wall displacement outlet Q-WL, Q-WR and Q-WK</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>General Information about displacement air outlets</p></div>
            </div>
        </div>
    );
    const productContent40 = (
        <div className="product-content">
            <h3 className='popup_title'>Placement flush with false ceiling or downstanding below the room ceiling, non-adjustable or adjustable.​​</h3>
            <p className='title'>Data</p>
            <p className='th'>Type Q-DN, non-adjustable</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>47 - 140 l/s [170 - 500 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>0.15 – 0.45 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Sizes</td>
                    <td className='td-2'>625 mm x 625 mm (square)<br />
                        625 mm x 1 250 mm (rectangular)</td>
                </tr>
            </table>

            <p className='th mt-3'>Type Q-DV, adjustable</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>94 - 140 l/s [340 - 500 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>0.30 - 0.45 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Sizes</td>
                    <td className='td-2'>625 mm x 625 mm (square)</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>For low-turbulence air supply from ceiling</li>
                <li>Discharge height 2.5 – 3.5 m</li>
                <li>Vertical downflow supply air jet</li>
                <li>Adjustable type also suitable for heating purposes; adjustment either by hand, or by thermostatic device or servomotor</li>
                <li>Flush with ceiling or downstanding</li>
                <li>Minimum horizontal distance to workplace 1.5 m</li>
                <li>Connection to supply air duct at side or from above</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Displacement air outlets commercial sector Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.4.6_Q-DN_Q-DV_Ceiling_displacement_outlet_06.2003-wTC_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - ceiling displacement outlet Q-DN and Q-DV</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.4.6_Q-DN_Q-DV_Ceiling_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Ceiling displacement outlet Q-DN and Q-DV</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/D1.4.0.2_Quellueftung-Allgemeine_Auslegung_04.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Allgemeine Informationen zur Quelluftung</p></div>
            </div>
        </div>
    );
    const productContent41 = (
        <div className="product-content">
            <h3 className='popup_title'>The rectangular floor displacement outlet ist designed to deliver supply air to commercial rooms with raised floors or floor plenums.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Supply air volume flow rate</td>
                    <td className='td-2'>up to 55 l/(s·m) [200 m³/(h·m)]</td>
                </tr>
                <tr>
                    <td className='td'>Temperature difference supply air to indoor air</td>
                    <td className='td-2'>–1 to –6 K</td>
                </tr>
                <tr>
                    <td className='td'>Unit length</td>
                    <td className='td-2'>800, 1 000, 1 200 (standard), 1 450, 1 700, 1 900 mm</td>
                </tr>
                <tr>
                    <td className='td'>Unit depth</td>
                    <td className='td-2'>140, 180, 260 (standard), 340 mm</td>
                </tr>
                <tr>
                    <td className='td'>Unit height</td>
                    <td className='td-2'>190 mm (standard)<br />
                        130 mm (supply air connection at the bottom)</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Combined mixing/displacement flow or hybrid flow as described in German VDI 3804 Guideline</li>
                <li>For mounting in raised Floors</li>
                <li>Volume flow rate up to 55 l/(s·m) [200 m³/(h·m)], one-way or two-way discharge</li>
                <li>Draught-free indoor air flow with very low air velocities in the occupied Zone</li>
                <li>Large supply air coverage (up to 6 m)</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Displacement air outlets commercial sector Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.5.2.3_Q-BR-Rectangular_floor_displacement_outlet_12.2010_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Rectangular floor displacement outlet Q-BR</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.5.2.4_Q-BR-Rectangular_floor_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Rectangular floor displacement outlet Q-BR</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>General Information about displacement air outlets</p></div>
            </div>
        </div>
    );
    const productContent42 = (
        <div className="product-content">
            <h3 className='popup_title'>Suitable for rooms where the supply air is discharged at points either at approx. 3 m height or directly above the floor.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 2 800 l/s [10 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 250 - DN 630</td>
                </tr>
                <tr>
                    <td className='td'>Primary penetration Depth</td>
                    <td className='td-2'>up to 14 m</td>
                </tr>
                <tr>
                    <td className='td'>Maximum supply air penetration depth</td>
                    <td className='td-2'>up to 20 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low-turbulence displacement flow</li>
                <li>Placement at approx. 3 m height or on the floor, free standing in room or in front of a pillar or wall</li>
                <li>Radial discharge direction</li>
                <li>Stepless adjustment of discharge direction from approximately horizontal to vertical downflow</li>
                <li>Adjustment by hand, servomotor or automatic thermal control unit</li>
                <li>Well suited for cooling and heating</li>
                <li>Connection to duct to EN 1506</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.1.1_VA-ZD_Circular_displacement_outlet-adjustable_damper_04.2018.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.1.1_VA-ZD_Circular_displacement_outlet_with_adjustabledamper_040.2018_neu.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent43 = (
        <div className="product-content">
            <h3 className='popup_title'>Suitable for rooms with high specific heat loads or light pollutants.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 700 l/(s•m) [2 500 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Length</td>
                    <td className='td-2'>1 000 - 2 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>350 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>765 and 1 150 mm</td>
                </tr>
                <tr>
                    <td className='td'>Coverage zone</td>
                    <td className='td-2'>up to 20 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low-turbulence displacement flow</li>
                <li>Installation on or directly above the floor</li>
                <li>Adjustable (VA-RV) or non-adjustable (VA-RN)</li>
                <li>Horizontal discharge; with adjustable type (VA-RV), discharge directed to floor when heating</li>
                <li>Flat shape</li>
                <li>Rectangular connection spigot positioned on top</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.2_VA-RV_VA-RN_Rectangular_displacement_outlet_06.2012_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.2_VA-RV_VA-RN_Rectangular_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent44 = (
        <div className="product-content">
            <h3 className='popup_title'>For factories with airborne dust, fibres or aerosols, and permanent cooling.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>85 – 695 l/(s•m) [300 – 2 500 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Width</td>
                    <td className='td-2'>140, 290 and 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Length</td>
                    <td className='td-2'>800 – 1 800 mm</td>
                </tr>
                <tr>
                    <td className='td'>Coverage zone of supply air jets</td>
                    <td className='td-2'>4 - 8 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low-turbulence displacement flow</li>
                <li>Installation directly below main supply air ducts, as a rule several in a row</li>
                <li>Umbrella-shaped discharge direction from horizontal to downward incline and vertical downflow</li>
                <li>For applications with permanent cooling</li>
                <li>On type partly covered for use directly in front of a wall</li>
                <li>Discharge surface detachable for easy cleaning</li>
                <li>Discharge height 3 to 4 m</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.3.1_VA-T_Trapezoidal_displacement_outlet_01.2018_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent45 = (
        <div className="product-content">
            <h3 className='popup_title'>For factories with airborne dust, aerosols or fibres and permanent cooling.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>70 – 415 l/(s•m) [250 – 1 500 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Width</td>
                    <td className='td-2'>250 and 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Length</td>
                    <td className='td-2'>1 200, 1 500 and 1 800 mm</td>
                </tr>
                <tr>
                    <td className='td'>Coverage zone of supply air jets</td>
                    <td className='td-2'>2 – 3 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low-turbulence displacement flow</li>
                <li>Installation along a wall or row of pillars; supply air intake through connection spigot positioned at the top or at the back, rectangular or circular</li>
                <li>For applications with permanent cooling</li>
                <li>Discharge direction from horizontal flow to vertical downflow</li>
                <li>Discharge height 3 to 4 m</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.3.2_VA-TH_Semi-trapezoidal_displacement_outlet_01.2018_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - VA-TH</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.3.2_VA-TH_Semi-trapezoidal_displacement_outlet_01.2018_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - VA-TH</p></div>
            </div>
        </div>
    );
    const productContent46 = (
        <div className="product-content">
            <h3 className='popup_title'>Suitable for factories where the supply air ducts must be placed with the air outlets at the walls and the supply air is to be discharged at low turbulence and large penetration depth into the occupied zone; suitable for cooling and heating.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>220 – 500 l/(s•m)
                        [800 – 1 800 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal lengths</td>
                    <td className='td-2'>1 200 to 2 400 mm</td>
                </tr>
                <tr>
                    <td className='td'>Max. supply air penetration depth</td>
                    <td className='td-2'>up to 20 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low-turbulence displacement flow</li>
                <li>Placement above the occupied zone, in front of walls or galleries</li>
                <li>Discharge height 3 to 5 m</li>
                <li>Discharge direction adjustable from +10° to -35° to horizontal, manually or with servomotor</li>
                <li>Placement at side of main supply air duct, singly or several in a row</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.4_VA-S_Swivel_displacement_outlet_05.2010-2-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.4_VA-S_Swivel_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent47 = (
        <div className="product-content">
            <h3 className='popup_title'>For factories where the supply air must be discharged downwards from a height of 4 to 10 m at low turbulence.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>280 – 2 200 l/s [1 000 – 8 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 315 – DN 710</td>
                </tr>
                <tr>
                    <td className='td'>Coverage radius</td>
                    <td className='td-2'>≤ 5 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low-turbulence displacement flow</li>
                <li>Placement above the occupied zone at a height of 4 to 10 m, freely suspended</li>
                <li>Jet direction more or less spread, vertical downflow</li>
                <li>When heating, higher jet momentum downwards by opening of core tube</li>
                <li>Well suited for cooling and heating</li>
                <li>Adjustment</li>
                <ul>
                    <li>via thermostatic control unit; advantages: no wiring, no auxiliary energy required</li>
                    <li>with electric actuator</li>
                    <li>with manual device</li>
                </ul>
                <li>Connection to circular duct to EN 1506</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.5_VA-PV_Radial_displacement_outlet_03.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.5_VA-PV_Radial_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent48 = (
        <div className="product-content">
            <h3 className='popup_title'>For halls where supply air must be discharged from a great height at low turbulence; suitable for cooling and heating.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>280 – 2 800 l/s [1 000 – 10 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Size</td>
                    <td className='td-2'>DN 630 and DN 800</td>
                </tr>
                <tr>
                    <td className='td'>Discharge height</td>
                    <td className='td-2'>6 – 30 m</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low-turbulence displacement flow, especially suited for aircraft painting Hangars</li>
                <li>Air discharge direction adjustable to internal loads</li>
                <li>Built-in electric actuator for adjustment of jet penetration depth</li>
                <li>Low pressure drop</li>
                <li>Temperature difference between supply air and return air up to –5 K in cooling mode and +8 K in heating mode</li>
                <li>Smooth operation in heating-up operation up to Δϑ = +10 K</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.6_VA-K_Conical_displacement_outlet_07.2014-1_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.6_VA-K_Conical_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent49 = (
        <div className="product-content">
            <h3 className='popup_title'>For decontaminating individual workplaces; placement above workplace.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>depends on size<br />
                        80 -800 l/s [300 - 3 000 m3/h]</td>
                </tr>
                <tr>
                    <td className='td'>Velocity u, related to L x W</td>
                    <td className='td-2'>0.15 – 0.4 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Size</td>
                    <td className='td-2'>adaptable to workplace</td>
                </tr>
                <tr>
                    <td className='td'>Coverage zone</td>
                    <td className='td-2'>zone directly below air outlet</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Placement above workplace to be protected</li>
                <li>Vertical supply air flow with extremely low turbulence</li>
                <li>Stable jet pattern at discharge velocities as low as 0.15 m/s</li>
                <li>Effective and economical protection of individual workplaces against pollutants</li>
                <li>Optional lateral shields to protect against cross convections</li>
                <li>Connection from above or at a side</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
        </div>
    );
    const productContent50 = (
        <div className="product-content">
            <h3 className='popup_title'>Shooting with firearms in indoor firing ranges releases gases and airborne particles which pollute the indoor air. Using a properly dimensioned HVAC system is a way of reducing the air pollutants to protect the health of shooters.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>depends on range cross section and amount of pollutants
                        (depending on type of firearm and shooting sequence)</td>
                </tr>
                <tr>
                    <td className='td'>Discharge velocity</td>
                    <td className='td-2'>0.25 - 0.35 m/s</td>
                </tr>
                <tr>
                    <td className='td'>Size</td>
                    <td className='td-2'>made to match the range cross section</td>
                </tr>
                <tr>
                    <td className='td'>Coverage</td>
                    <td className='td-2'>entire range cross section and length</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Breathing zone of the shooter is free of hazardous substances</li>
                <li>High thermal comfort because of draught-free air supply</li>
                <li>Air distribution without influence on ballistics</li>
                <li>Easy and quick assembly (modular system)</li>
                <li>Optional integration of windows and doors for the supervision of the shooters</li>
                <li>Stable piston flow, even at temperature differences of up to ±4 K between supply air and indoor air</li>
                <li>Individual design, tailored to the architecture</li>
                <li>Robust powder coating with free choice of colour</li>
                <li>Support in layout and concept by our experts</li>
                <li>Proof of function during commissioning if desired</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.8.0_Displacement_ventilation_for_indoor_firing_ranges_06.2014_Bildschirmversion_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Brochure - Displacement ventilation for indoor firing ranges</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.8.1_VA-RSA_Displacement_ventilation_for_indoor_firing_ranges_03.2014_Kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.5.8.1_VA-RSA_Displacement_outlet_for_indoor_firing_ranges_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/technical-article-firing-range-2014-03.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Articles - Displacement ventilation installed at the most modern police indoor firing range of Europe</p></div>
            </div>
        </div>
    );
    const productContent51 = (
        <div className="product-content">
            <h3 className='popup_title'>For air distribution in assembly rooms with fixed seating, air supply from seat leg.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Supply air volume flow rate</td>
                    <td className='td-2'>up to 14 l/s [50 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 100, DN 127 and DN 190</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 16 dB(A) ref. 10-12 W</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>For rooms with raised floors or floor plenums and permanent seating</li>
                <li>Air outlet built into seat leg</li>
                <li>Low-turbulence, horizontal, radial jet dispersion above floor</li>
                <li>Very low sound power level</li>
                <li>Suitable for all seat designs</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.6.2_Q-ST_Seat_displacement_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent52 = (
        <div className="product-content">
            <h3 className='popup_title'>For air distribution in assembly rooms with banked seating. Air supply from step front; linear and circular shape.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Type Q-SR</td>
                    <td className='td-2'>(round frontal plate)</td>
                </tr>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>DN 80: ≤ 10 l/s [35 m³/h]<br />
                        DN 100: ≤ 16.5 l/s [60 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 27 dB(A) ref. 10-12 W</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>80 mm</td>
                </tr><br />
                <tr>
                    <td className='td'>Type Q-SL</td>
                    <td className='td-2'>(rectangular frontal plate)</td>
                </tr>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 21 l/(s • m) [75 m³/(h • m)]</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 15 dB(A) ref. 10-12 W</td>
                </tr>
                <tr>
                    <td className='td'>Length</td>
                    <td className='td-2'>variable</td>
                </tr>
                <tr>
                    <td className='td'>Standard height</td>
                    <td className='td-2'>120 mm</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>75 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>For rooms with steps and raised floor plenums</li>
                <li>Air outlet built into step front</li>
                <li>Low-turbulence jet dispersion near Floor</li>
                <li>Low sound power Level</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.6.3_Q-SR-Q-SL_Step_displacement_outlets_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent53 = (
        <div className="product-content">
            <h3 className='popup_title'>For air distribution in assembly rooms with banked seating. Air supply from step front; circular shape, in three options.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Function / Kind</td>
                    <td className='td-2'>DS-DD: Twist outlet<br />
                        DS-RA: Radial outlet - (square face on request)<br />
                        DS-BA: Floor outlet</td>
                </tr>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'></td>
                </tr>
                <tr>
                    <td className='td'>DS-DD-DN 63</td>
                    <td className='td-2'>≤ 3.3 l/s [12 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>DS-DD-DN 100</td>
                    <td className='td-2'>≤ 10 l/s [35 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>DS-RA-DN 100</td>
                    <td className='td-2'>≤ 10 l/s [35 m³/h]</td>
                </tr><br />
                <tr>
                    <td className='td'>DS-BA-DN 150</td>
                    <td className='td-2'>≤ 10 l/s [35 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 18 dB(A) ref. 10-12 W</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>For assembly rooms with floor steps, with and without fixed seating</li>
                <li>Built into step front</li>
                <li>Turbulent, radial discharge flow with intensive indoor air admixture resulting in rapid drop in jet velocity and fast temperature equalization</li>
                <li>Installation in wooden or concrete steps</li>
                <li>Connection to pressurized plenum</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.6.4.1_DS_Step_twist_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent54 = (
        <div className="product-content">
            <h3 className='popup_title'>For air distribution in assembly rooms with banked seating. Air supply from step front; circular shape.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 6.9 l/s [≤ 25 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal size</td>
                    <td className='td-2'>DN 80</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 24 dB(A) ref. 10-12 W</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Air distribution system for auditoria, convention centres, theatres, and other assembly rooms</li>
                <li>For integration into step risers ≥ 130 mm in height</li>
                <li>Microclimate air distribution system with uniform temperature distribution in the occupied zone and draught-free indoor air flow in the microclimate zone</li>
                <li>Low sound power level</li>
                <li>Maximum temperature difference between supply and indoor air –5 K between supply and return air –12 K, depending on internal heat loads and floor-to-ceiling height</li>
                <li>Low-outlay solution for comfortable climate</li>
                <li>Recommended minimum supply air temperature 18 °C</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.6.4.2_DS-RA-DN80_Step_twist_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent55 = (
        <div className="product-content">
            <h3 className='popup_title'>For air distribution into assembly rooms with raised, stepped floors; air supply from the step front; rectangular shape.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>≤ 12.5 l/s [≤ 45 m3/h]</td>
                </tr>
                <tr>
                    <td className='td'>Sound power level</td>
                    <td className='td-2'>≤ 22 dB(A) ref.10-12 W</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Air distribution system for auditoria, conference centres, theatres, and other assembly Rooms</li>
                <li>For integration into the step front</li>
                <li>The outlet can be mounted in different step types from the room side</li>
                <li>Low outlay option to ensure thermal comfort</li>
                <li>Strong mixing of supply air jets with indoor air at floor level; as a result, low vertical temperature gradient in the occupied zone</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Imagebrochure</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.6.4.3_SD-L_Linear_step_twist_outlet_07.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.6.4.3_SD-L_Linear_Step_twist_outlet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text</p></div>
            </div>
        </div>
    );
    const productContent56 = (
        <div className="product-content">
            <h3 className='popup_title'>For both constant and variable air volume flow rates. Circular volume flow controller with built-in electronic control system for installation in supply and return air ducts of HVAC systems.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range</td>
                    <td className='td-2'>up to 2 544 l/s [9 160 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Size</td>
                    <td className='td-2'>DN 100 – DN 450</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Compact design with measuring cross, compact controller and damper blade</li>
                <li>Compact controller from Belimo with measurement recording device, volume flow sensor and actuator</li>
                <li>Connection to ducts to EN 1506 and EN 13180 as well as flange connection to EN 12220</li>
                <li>Airtight damper blade to EN 1751</li>
                <li>Optionally available with external insulating shell</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT1.7.1_VRI-DN_Volume_flow_controller_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - VRI-DN</p></div>
            </div>
        </div>
    );
    const productContent57 = (
        <div className="product-content">
            <h3 className='popup_title'>Vent and drain caps are being used in water-carrying systems.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal sizes</td>
                    <td className='td-2'>DN 8 and DN 10 - mainly applied as relief cap<br />
                        DN 15 and DN 20 - mainly applied as drain cap</td>
                </tr>
                <tr>
                    <td className='td'>Nominal pressure</td>
                    <td className='td-2'>PN 40</td>
                </tr>
                <tr>
                    <td className='td'>Operating temperature</td>
                    <td className='td-2'>max. 180 °C</td>
                </tr>
            </table>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><img src={viewfile} alt="view_file" className="popup_icon" /></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection (in German only)</p></div>
            </div>
        </div>
    );
    const productContent58 = (
        <div className="product-content">
            <h3 className='popup_title'>High-capacity elements for installation above open, suspended ceilings; suitable for cooling all room types and applications but also usable for heating.</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 000 mm - 4 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>350 mm - 1 475 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>85 mm</td>
                </tr>
                <tr>
                    <td className='td'>Pipe spacing</td>
                    <td className='td-2'>125 mm</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>push-in fitting or press fitting</td>
                </tr>
                <tr>
                    <td className='td'>Installation height</td>
                    <td className='td-2'>≥ 185 mm</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>powder coating to RAL (standard RAL 9005)</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Standard cooling capacity 175 W/m² of cooling element suspended false ceiling (to DIN EN 14 240)</li>
                <li>Suitable for high cooling capacities, as required in offices with high heat loads, television studios, technical rooms, or in the industrial sector</li>
                <li>Uses the retention capacity of the concrete ceiling</li>
                <li>Low temperature differences in the occupied zone</li>
                <li>Combinable with any air distribution system</li>
                <li>Cooling elements are independent of the false ceiling, so that mechanical installations can be carried out largely separately from ceiling construction</li>
                <li>Low installation height saves on building costs and makes this systems particularly suitable for renovation and refitting</li>
                <li>The basic element is copper serpentine pipework in one piece, thus no special requirements for chilled water quality; low system costs, long lifetime, assured quality, operating pressure up to 16 bar (consult us on higher values)</li>
                <li>No combustible components</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Static cooling ceiling system SKS-4/3 for concealed installation</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.1.1.1_SKS-4-3_Static_Cooling_Ceiling_System_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Static cooling ceiling system SKS-4/3 for concealed installation</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.1_K181e_Cooling_ceiling_technology_09.2001_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Auslegungskriterien für thermische Behaglichkeit</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Heating and cooling with ceilings</p></div>
            </div>
        </div>
    );
    const productContent59 = (
        <div className="product-content">
            <h3 className='popup_title'>High-capacity elements for installation above open, suspended ceilings; suitable for cooling rooms of different types and uses, but also usable for heating.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 000 mm - 4 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>400 mm - 1 200 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>120 mm</td>
                </tr>
                <tr>
                    <td className='td'>Pipe spacing</td>
                    <td className='td-2'>100 mm</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>push-in fitting or press fitting</td>
                </tr>
                <tr>
                    <td className='td'>Installation height</td>
                    <td className='td-2'>≥ 200 mm</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>powder coating to RAL (standard RAL 9005)</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Standard cooling capacity 216 W/m² of cooling element without suspended ceiling (with reference to EN 14 240), with high heat removal by convection and radiation</li>
                <li>Suitable for high cooling capacities, as required in offices with high heat loads, television studios, technical rooms, or in the industrial sector</li>
                <li>Uses the retention capacity of the concrete ceiling</li>
                <li>Design and installation of mechanical equipment and ceiling can largely occur separately</li>
                <li>Combinable with any air distribution system</li>
                <li>The basic element is copper serpentine pipework in one piece, thus long lifetime, assured quality, operating pressure up to 6 bars (consult us on higher values)</li>
                <li>Low installation height saves on building costs and makes this systems particularly suitable for renovation and refitting</li>
                <li>No combustible components</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.1.2_SKS4-3-duo_Static_cooling_ceiling_system_03.2008-1-Web_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Static cooling ceiling system SKS-4/3-duo for concealed installation</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.1.1.2_SKS-4-3-duo_Static_Cooling_Ceiling_System_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Static cooling ceiling system SKS-4/3-duo, for concealed installation</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.1_K181e_Cooling_ceiling_technology_09.2001_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Heating and cooling with ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent60 = (
        <div className="product-content">
            <h3 className='popup_title'>High-capacity elements for installation as exposed ceiling elements with elegant panelling design. They can be used to make free-hanging chilled sails or chilled islands to be integrated into any type of suspended ceiling. This system is easy to combine with lighting and other ceiling utilities such as air outlets, sprinklers and loudspeakers.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 000 mm - 4 000 mm<br />
                        (special lengths up to 6 000 mm)</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>430 mm - 1 480 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>60 mm</td>
                </tr>
                <tr>
                    <td className='td'>Pipe spacing</td>
                    <td className='td-2'>150 mm</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>push-in fitting or press fitting</td>
                </tr>
                <tr>
                    <td className='td'>Recommended mounting height</td>
                    <td className='td-2'>≥ 185 mm, i.e. min. 125 mm from element top to ceiling (for no drop in output)</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>powder coating to RAL (standard RAL 9010)</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Standard cooling output 160 W/m² of element if designed as a chilled sail (to EN 14 240); heat removal largely by radiation</li>
                <li>Suitable for rooms with cooling loads of up to 120 W/m² under comfortable conditions</li>
                <li>For both cooling and heating</li>
                <li>The system utilizes the storage capacity of the concrete slab</li>
                <li>Well suited for renovation and retrofit applications</li>
                <li>Special fin geometries and underside patterns easy to manufacture</li>
                <li>The core of the system is copper serpentine pipework in one piece, thus long lifetime and assured quality, operating pressure up to 6 bars (consult us on higher values)</li>
                <li>No combustible components</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.2_SKS-5-3_Static_cooling_ceiling_system_01.2010_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Static cooling ceiling system SKS-5/3 for visible installation</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.1.2_SKS-5-3_Static_Cooling_System.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Static cooling ceiling system SKS-5/3, for visible installation</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.1_K181e_Cooling_ceiling_technology_09.2001_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Heating and cooling with ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Auslegungskriterien für thermische Behaglichkeit</p></div>
            </div>
        </div>
    );
    const productContent61 = (
        <div className="product-content">
            <h3 className='popup_title'>Designed for use with plane metal acoustic ceilings to make radiant cooling or heating ceilings. The permanent large-surface contact between the cooling elements and the ceiling panels is done with adhesive.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>500 mm - 3 000</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>≤ 16 pipe rows x pipe spacing</td>
                </tr>
                <tr>
                    <td className='td'>Pipe spacing</td>
                    <td className='td-2'>≥ 90 mm (standard)</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>≤ 15 mm</td>
                </tr>
                <tr>
                    <td className='td'>Suspension height</td>
                    <td className='td-2'>≥ 100 mm</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>push-in fitting</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Heat transfer mainly by radiation, resulting in very high thermal comfort</li>
                <li>Standard cooling capacity up to 75 W/m² at Δt = 8 K for KKS-3 elements glued onto metal acoustic ceiling panels made of sheet metal (to EN 14240)</li>
                <li>High system flexibility thanks to various element designs and sizes available; thus any type of metal ceiling can be used for cooling and the ceiling keeps all other desired properties, e.g. good sound absorption</li>
                <li>Low suspension height – cooling ceiling elements assembled no higher than the ceiling panel</li>
                <li>The core of the system is a copper serpentine pipework with aluminum heat conducting profiles, thus long service life and assured quality; operating pressure 6 bars (higher values on enquiry)</li>
                <li>Reduction of “grey energy” and thus well suited for Leeds, DGNB, etc. buildings</li>
                <li>Easy and safe Installation</li>
                <li>No combustible components</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Contact cooling ceiling system KKS-3/LD, for metal ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.1.1_KKS-3LD_Contact_cooling_ceiling_system_Krantz.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - KKS-3/LD</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Heating and cooling with ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Auslegungskriterien für thermische Behaglichkeit</p></div>
            </div>
        </div>
    );
    const productContent62 = (
        <div className="product-content">
            <h3 className='popup_title'>Designed for use with plane metal acoustic ceilings to make radiant cooling or heating ceilings. The permanent large-surface contact between the cooling elements and the ceiling panels is done with magnets.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>500 – 3 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>≤ 16 pipe rows x pipe spacing</td>
                </tr>
                <tr>
                    <td className='td'>Pipe spacing</td>
                    <td className='td-2'>≥ 90 mm (standard)</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>≤ 15 mm</td>
                </tr>
                <tr>
                    <td className='td'>Suspension height</td>
                    <td className='td-2'>≥ 100 mm</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>push-in fitting</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Heat transfer mainly by radiation, resulting in very high thermal comfort</li>
                <li>Standard cooling capacity up to 95 W/m² for KKS-5 elements laid onto metal acoustic ceiling panels made of sheet metal (to EN 14240)</li>
                <li>High system flexibility thanks to various element designs and sizes available; thus any type of ceiling can be used for cooling and the ceiling keeps all other desired properties, e.g. good sound absorption</li>
                <li>Low suspension height</li>
                <li>The core of the system is copper serpentine pipework with Al heat conducting profiles, thus long service life and assured quality; operating pressure 6 bars (higer values on enquiry)</li>
                <li>Easy and safe installation</li>
                <li>No combustible components</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/DAT2.2.1.2_KKS-5-LD-M_Kontakt-Kuehldeckensystem_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Heating and cooling with ceilings</p></div>
            </div>
        </div>
    );
    const productContent63 = (
        <div className="product-content">
            <h3 className='popup_title'>Provided for combination with perforated or unperforated gypsum board panels (and matching suspension structure) from various manufacturers to produce radiant cooling or heating ceilings. The cooling elements are fitted into the suspension structure.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>500 mm to 2 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>unperforated: 420 mm<br />
                        perforated: 250 mm</td>
                </tr>
                <tr>
                    <td className='td'>Overall system height</td>
                    <td className='td-2'>≥ 80 mm</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>press fitting</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Heat transfer largely by radiation, with very high thermal comfort as a result</li>
                <li>Standard cooling output up to 69 W/m² at Δt = 8 K for KKS-4 elements combined with thermal gypsum board panels (to DIN EN 14240), heating capacity 102 W/m² (at Δt 12 K)</li>
                <li>Also suitable for heating</li>
                <li>Sound-absorbing types available</li>
                <li>The core of the system is a copper serpentine pipework with an aluminum heat conducting plate, thus long lifetime and assured quality; operating pressure 6 bars (higher values subject to enquiry)</li>
                <li>Easy and safe installation according to professional methods in use in building services and drywall construction</li>
                <li>No combustible components</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Contact cooling ceiling system KKS-4/GK, for gypsum board ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.2.2_KKS-4-GK_Contact_cooling_ceiling_system_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Contact cooling ceiling system KKS-4/GK, for gypsum board ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Heating and cooling with ceilings</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent64 = (
        <div className="product-content">
            <h3 className='sub-title'>– PKS-D, for plastered ceilings<br />
                – PKS-W, for plastered walls</h3>
            <h3 className='popup_title'>Designed to connect shaped serpentine piping inside or outside the plaster area in order to make radiant cooling or heating ceilings or walls. The permanent large-surface contact between the PKS element and the plaster area is done by on-site plastering.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 000 mm – 5 400 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>	4 pipe rows, corresponds to 360 mm *)<br />
                        6 pipe rows, corresponds to 540 mm *)</td>
                </tr>
                <tr>
                    <td className='td'>Nominal thickness</td>
                    <td className='td-2'>20 – 25 mm</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>press fitting; soldered connection on request</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Heat transfer mainly by radiation, with very high thermal comfort as a result</li>
                <li>Low overall thickness</li>
                <li>For versatile applications thanks to customizable dimensions</li>
                <li>The core of the system is copper serpentine piping which ensures quality and long lifetime; operating pressure 6 bars</li>
                <li>Easy and safe installation according to professional methods, irrespective of the outside temperature</li>
                <li>No combustible components</li>
                <li>Quick response time owing to small plaster thickness</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/DAT2.2.3_PKS_Putz-Kuehldeckensystem_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling System Description</p></div>
            </div>
        </div>
    );
    const productContent65 = (
        <div className="product-content">
            <h3 className='popup_title'>The Multifunction exposed ceiling is an ideal solution for any open ceiling architecture. Here, a ceiling panel is combined with state-of-the-art cooling ceiling technology into a highly functional and flexible unit. The result is a visually-appealing panel that provides a high specific cooling and heating output while at the same time maintaining a high degree of thermal comfort.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 500 – 5 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>1 150 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>50 mm</td>
                </tr>
                <tr>
                    <td className='td'>Minimum suspension height</td>
                    <td className='td-2'>150 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Preassambled, compact units with high performance</li>
                <li>Designed for for cooling and heating</li>
                <li>Well-suited for combination with other heating and cooling systems</li>
                <li>Integration of lighting fixtures, air outlets, speakers, smoke detectors also possible</li>
                <li>Simple and quick installation on-site</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Mutifunction Exposed Ceiling</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical article Multifunction sail AVACS</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Video - Multifunctional Sails</p></div>
            </div>
        </div>
    );
    const productContent66 = (
        <div className="product-content">
            <h3 className='popup_title'>AVACS stands for Air Ventilation And Cooling System. The AVACS multifunction sail combines the following functions: cooling, heating, indoor air circulation and sound absorption, which are performed in compliance with thermal comfort criteria.The multifunction sail AVACS is designed for use in combination with metal tile sails from various brands.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 500 – 5 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>1 150 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>50 mm</td>
                </tr>
                <tr>
                    <td className='td'>Minimum suspension height</td>
                    <td className='td-2'>150 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Heat transfer by convection and radiation, resulting in high thermal comfort</li>
                <li>Very high output in relation to the active sail area (area-output ratio) thanks to built-in induction unit (not visible from below)</li>
                <li>Very high thermal comfort thanks to air distribution above and below the sail</li>
                <li>Suitable for refurbishment of offices and exhibition space</li>
                <li>Different types of finish are possible; ceiling services can be integrated</li>
                <li>Good acoustic properties</li>
                <li>The core of the system is a copper serpentine pipework with Al heat conducting profiles, which assures long service life and quality; operating pressure 6 bar (higher values on enquiry)</li>
                <li>No combustible components</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Multifunction Exposed Ceiling - Solutions with System</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Multifunctional Sail AVACS</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.3.1_AVACS_Multifunction_sail_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Multifunctional Sail AVACS</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical article Multifunction sail AVACS</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Video - Multifunctional Sails from Krantz</p></div>
            </div>
        </div>
    );
    const productContent67 = (
        <div className="product-content">
            <h3 className='popup_title'>The Multifunction exposed ceiling AVACS recirculated air is a variation on our AVACS supply air system. Our proven cooling ceiling technology is combined with an AVACS recirculated air fan, invisible to the user. The result is a visually-appealing panel that provides a high specific cooling and heating output while at the same time maintaining a high degree of thermal comfort.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 500 – 5 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>1 150 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>50 mm</td>
                </tr>
                <tr>
                    <td className='td'>Minimum suspension height</td>
                    <td className='td-2'>150 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Preassambled, compact units with high performence</li>
                <li>Designed for for cooling and heating</li>
                <li>Well-suited for combination with other heating and cooling systems</li>
                <li>Integration of lighting fixtures, air outlets, speakers, smoke detectors also possible</li>
                <li>Simple and quick installation on-site</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Multifunction Exposed Ceiling - Solutions with System</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Multifunctional Sail AVACS</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical article Multifunction sail AVACS</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Video - Multifunctional Sails from Krantz</p></div>
            </div>
        </div>
    );
    const productContent68 = (
        <div className="product-content">
            <h3 className='popup_title'>The multifunction sail KrantzCool has been developed for suspension close to the concrete ceiling and is characterized by its extremely compact design. Due to reflow of secondary air above the ceiling sail, a uniform appearance of the ceiling sail with perforations throughout is possible. KrantzCool offers a high specific cooling and heating capacity. Because of its two-sided supply air discharge with horizontal flow pattern and the use of the Coanda effect, supply air is well-mixed with indoor air. This type of air distribution ensures that air speeds are kept low in occupied areas.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 500, 1 800, 2 100, 2 400, 2 700 and 3 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>560 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>155 mm</td>
                </tr>
                <tr>
                    <td className='td'>Minimum suspension height</td>
                    <td className='td-2'>180 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Extremely compact</li>
                <li>High specific cooling and heating capacity</li>
                <li>Two-sided supply air outlet</li>
                <li>Uniform appearance of ceiling sail with perforations throughout</li>
                <li>Well-suited for combination with other multifuncional sails, such as AVACS</li>
                <li>Reflow of secondary air above the ceiling sails</li>
                <li>Spreading out of supply air volume flow</li>
                <li>Easy and quick installation</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Multifunction Exposed Ceiling - Solutions with System</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technische Auslegung Krantz Cool</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent69 = (
        <div className="product-content">
            <h3 className='popup_title'>Standard or individual customized chilled sails using any type of ceiling together with combinable cooling ceiling elements or even chilled beams in any dimensions and designs.​</h3>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Prefabricated, compact units with high capacity</li>
                <li>Can be used for cooling and heating</li>
                <li>Can incorporate lighting, air outlets, loudspeakers, smoke detectors and other ceiling utilities</li>
                <li>Easy and rapid installation on site</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent70 = (
        <div className="product-content">
            <h3 className='popup_title'>Standard or customized chilled sails made up of gypsum board panels (perforated or unperforated) combined with KKS elements or other cooling and heating systems.​</h3>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Prefabricated compact units</li>
                <li>Can be used for cooling and heating</li>
                <li>Combinable with other cooling and heating systems</li>
                <li>Can incorporate lighting, air outlets, loudspeakers, smoke detectors and other ceiling utilities</li>
                <li>Easy and rapid installation on site</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent71 = (
        <div className="product-content">
            <h3 className='popup_title'>For room cooling in the commercial sector, trade and industry, with high cooling capacity. Various installation options: above open ceilings, integrated into ceilings, in visible suspension from the concrete ceiling, or built into the chilled sails.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>300, 400, 500 and 600 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>180 und 250 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 200, 1 500, 1 800, 2 100, 2 400, 2 700 and 3 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>DK-FZ: primed similar RAL 9011 painted or unpainted<br />
                        DK-FS: similar RAL 9010 painted desired colour to RAL… on request</td>
                </tr>
                <tr>
                    <td className='td'>Connection type:</td>
                    <td className='td-2'>sleeve Rp ½, or calibrated pipe and suitable for push-in fitting</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Standard cooling capacity up to 400 W/m
                    (B = 600 mm, H = 250 mm, measured following DIN EN 14 518)</li>
                <li>"Silent cooling" principle with heat, removal almost solely by way of convection</li>
                <li>Compact construction design and easy mounting</li>
                <li>Well suited for refitting</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.1_K181e_Cooling_ceiling_technology_09.2001_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.4.1_DK-F_Chilled_beam_05.2005_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Chilled Beam DK-F</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/layout-specifications-for-thermal-comfort-.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications</p></div>
            </div>
        </div>
    );
    const productContent72 = (
        <div className="product-content">
            <h3 className='popup_title'>For room cooling and heating in commercial and industrial buildings. High cooling capacities; with fresh air supply to enhance the indoor air quality. The secondary air is taken directly from the occupied zone. The supply air is discharged on two sides. This chilled beam is designed for integration into any type of suspended ceiling. It can also be freely suspended, but then it requires so-called wings for stabilizing the air jets.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>Model 300<br />
                        300 mm (for flush Installation)<br />
                        450 mm (if freely suspended, incl. "wings")</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>Model 600<br />
                        600 mm (for flush Installation)<br />
                        700 mm (if freely suspended, incl. "wings")</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>285 mm (model 300)<br />
                        300 mm (model 600)</td>
                </tr>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 200, 1 500, 1 800, 2 100, 2 400, 2 700 and 3 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Primary air flow rate	</td>
                    <td className='td-2'>3 – 25 l/(s•m) [10 – 90 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>powder coating to RAL (standard RAL 9010)</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>push-in fitting or pipe end for pressing<br />
                        primary air spigot DN 100 – DN 150
                    </td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Compact unit for fresh air supply as well as for cooling and heating according to DIN EN 15116
                    – Model 300:<br />
                    Cooling capacity up to 620 W/m<br />
                    Heating capacity up to 315 W/m<br />
                    – Model 600<br />
                    Cooling capacity up to 826 W/m<br />
                    Heating capacity up to 352 W/m</li>
                <li>Reflow surface area for secondary air intake</li>
                <li>Many design options with various types of screens, wings, and connections to suspended ceilings</li>
                <li>Heat exchanger cleanable from room thanks to easily detachable screen</li>
                <li>Thanks to low height, well suited for low storeys in new or refurbished buildings</li>
                <li>Horizontal air distribution and low air velocities in the occupied Zone</li>
                <li>Low sound power Level</li>
                <li>Optimum layout possible thanks to various sizes of primary air nozzles</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.1_K181e_Cooling_ceiling_technology_09.2001_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.4.2.1_DK-LIG-Z_Chilled_beam_05.2003_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Chilled Beams DK-LIG/Z Model 300</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.4.2.1_DK-LIG-Z_Chilled_beam_05.2003_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Chilled beams DK-LIG/Z Model 600</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/DK-LIG-Z_Ausschreibungstext_2011-03.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text DK LIG Z 300 (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.4.2_DK-LIG-Z-600_Chilled_beam_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text DK LIG Z 600</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent73 = (
        <div className="product-content">
            <h3 className='popup_title'>For room cooling and heating in the commercial sector, trade and industry, for high cooling capacities and simultaneous fresh air supply to improve indoor air quality and humidity control. The secondary air is removed directly from occupied zone. The supply air is discharged on one side, which enables parallel placement to the facade or walls. This chilled beam is designed for integration into suspended ceilings made from metal, gypsum plasterboard, mineral fibres ect., or for downstanding installation.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>
                        300 mm (for flush installation)<br />
                        375 mm (downstanding)</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>265 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 200, 1 500, 1 800, 2 100, 2 400, 2 700 and 3 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Supply air flow</td>
                    <td className='td-2'>8 – 25 l/(s•m) [30 – 90 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>powder coating to RAL (standard RAL 9010)</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>sleeve Rp ½, or calibrated pipe and suitable for push-in fitting, spigot DN 100 – DN 150</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Compact unit for fresh air supply as well as for cooling and heating according to DIN EN 15116:<br />
                    Cooling capacity up to 620 W/m<br />
                    Heating capacity up to 315 W/m</li>
                <li>Reflow surface for secondary air integrated into the chilled beam; an extra reflow surface is thus no longer required in closed ceiling systems</li>
                <li>Many design options with various types of screens, wings and connections to suspended ceilings</li>
                <li>Heat exchanger cleanable from room thanks to easily detachable screen</li>
                <li>Thanks to low height, suitable for low storeys in new or refurbished Buildings</li>
                <li>Horizontal air distribution with low velocities in the occupied Zone</li>
                <li>Low sound power Level</li>
                <li>Optimum layout made possible by the range of Primary air nozzles available</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Chilled Beam DK-LIG/E</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/DAT2.4.2_DK-LIG_Aktiver_Deckenkuehlkonvektor_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Chilled beam DK-LIG/E (only in German)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent74 = (
        <div className="product-content">
            <h3 className='popup_title'>For room cooling and heating in commercial buildings; designed for high cooling capacities along with fresh air supply to enhance the indoor air quality. The secondary air is drawn directly from the occupied zone. The supply air is discharged below the ceiling. This chilled beam is designed for wall or ceiling mounting. It is most suitable for hotel rooms, individual offices, or lobbies.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Primary air volume flow rate</td>
                    <td className='td-2'>5.5 – 23 l/(s•m) [20 – 83 m³/(h•m)]</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>450 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>192.5 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>900, 1 200, 1 500, 1 800, 2 100, 2 400, 2 700 and 3 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>unpainted or powder coated to a RAL colour (standard: RAL 9005)</td>
                </tr>
                <tr>
                    <td className='td'>Connection type</td>
                    <td className='td-2'>push-in fitting or pipe end suitable for pressing;<br />
                        primary air spigot DN 100 – DN 150</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Compact unit for fresh air supply as well as cooling and heating according to DIN EN 15116<br />
                    – waterside cooling capacity up to 660 W/m<br />
                    – waterside heating capacity up to 320 W/m</li>
                <li>Its low overall height makes it suitable for low floor-to-ceiling heights in new or refurbished Buildings</li>
                <li>Low sound power level</li>
                <li>The range of primary air nozzles available enables optimum unit layout</li>
                <li>Simple installation</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent75 = (
        <div className="product-content">
            <h3 className='popup_title'>For indoor cooling and heating in commercial buildings with fresh air supply to enhance indoor air quality. Air outlet with integrated damper control; for temperature-dependent switching of the discharge direction between cooling and heating mode by automatic thermostat adjustment unit. The chilled beam is designed for ceiling mounting close to façades and is particularly suitable for installation in office and administrative buildings.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal length</td>
                    <td className='td-2'>1 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal width</td>
                    <td className='td-2'>220 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal height</td>
                    <td className='td-2'>250 mm</td>
                </tr>
                <tr>
                    <td className='td'>Primary air flow rate</td>
                    <td className='td-2'>8.3 – 22 l/(s·m) [30 – 80 m³/(h·m)]</td>
                </tr>
                <tr>
                    <td className='td'>Surface finish</td>
                    <td className='td-2'>Visible surfaces powder coated to a RAL colour</td>
                </tr>
                <tr>
                    <td className='td'>Primary air connection</td>
                    <td className='td-2'>DN 100 – DN 125 depending on position of spigots</td>
                </tr>
                <tr>
                    <td className='td'>Heat exchanger</td>
                    <td className='td-2'>two-pipe system or four-pipe System</td>
                </tr>
                <tr>
                    <td className='td'>Water connection</td>
                    <td className='td-2'>pipe end for push-in fitting or press-fitted connection, Da = 15 mm</td>
                </tr>
                <tr>
                    <td className='td'>Installation type</td>
                    <td className='td-2'>concealed installation</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Compact unit for fresh air supply as well as cooling and heating to DIN EN 15116<br />
                    – waterside cooling capacity up to 400 W/m<br />
                    – waterside heating capacity up to 275 W/m</li>
                <li>Its low overall height makes it suitable for low floorto-ceiling heights in new or refurbished buildings</li>
                <li>Self-adjustable air outlet</li>
                <li>Low sound power Level</li>
                <li>The range of primary air nozzles available enables optimum unit layout</li>
                <li>Simple Installation</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling Technology</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Layout Specifications for thermal comfort</p></div>
            </div>
        </div>
    );
    const productContent76 = (
        <div className="product-content">
            <h3 className='popup_title'>Ventilation unit for integration into the parapet of a facade, with direct outdoor and exhaust air Connections. Suitable for new or refurbished buildings.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Supply/Return air<br />
                        volume flow rate</td>
                    <td className='td-2'>max. 33 l/s [120 m³/h]<br />
                        respectively</td>
                </tr>
                <tr>
                    <td className='td'>Amount of heat recovered</td>
                    <td className='td-2'>approx. 50%</td>
                </tr>
                <tr>
                    <td className='td'>Total cooling output</td>
                    <td className='td-2'>500 W</td>
                </tr>
                <tr>
                    <td className='td'>- for cooling down 100% outdoor air</td>
                    <td className='td-2'>240 W</td>
                </tr>
                <tr>
                    <td className='td'>- for room cooling when tR = 26 °C</td>
                    <td className='td-2'>260 W</td>
                </tr>
                <tr>
                    <td className='td'>Cold water temperature</td>
                    <td className='td-2'>14 / 16 °C</td>
                </tr>
                <tr>
                    <td className='td'>Total heating output</td>
                    <td className='td-2'>880 W</td>
                </tr>
                <tr>
                    <td className='td'>- for heating up 30% outdoor air</td>
                    <td className='td-2'>560 W</td>
                </tr>
                <tr>
                    <td className='td'>- for room heating when tR = 22 °C</td>
                    <td className='td-2'>320 W</td>
                </tr>
                <tr>
                    <td className='td'>Hot water temperature</td>
                    <td className='td-2'>50 / 40 °C</td>
                </tr>
                <tr>
                    <td className='td'>Operating voltage</td>
                    <td className='td-2'>230 V / 50 Hz</td>
                </tr>
                <tr>
                    <td className='td'>Width</td>
                    <td className='td-2'>414 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>2 529 mm</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>174 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>For cooling, heating, and fresh air supply from the window parapet</li>
                <li>Outdoor air intake and exhaust air extraction directly through the facade, i.e. ductless ventilation</li>
                <li>F5 filter class (F7 optional)</li>
                <li>Various modes of operation to meet different user requirements:</li>
                <ul>
                    <li>Cooling</li>
                    <li>Heating</li>
                    <li>Operation with heat recovery</li>
                    <li>Operation with recirculated air</li>
                    <li>Operation with outdoor air</li>
                    <li>3-speed fan Operation</li>
                </ul>
                <li>Hygiene tested to German guidelines VDI 6022, sheets 1 and 3, and VDI 3803, to EN 13779 and to DIN 1946-4</li>
                <li>Easy to service and clean (no disassembly needed)</li>
                <li>Complete condensate drain</li>
                <li>Low sound power Level</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.5.1.3_LG-ZA-M-SB_Facade_ventilation_unit_10.2002_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Ventilation unit LG-ZA-M-SB</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/DAT2.5.1.3_LG-ZA-M-SB_Dezentrales_Lueftungsgeraet_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text (in German only)</p></div>
            </div>
        </div>
    );
    const productContent77 = (
        <div className="product-content">
            <h3 className='popup_title'>Induction unit for cooling, heating, and fresh air supply along the façade, through window parapets; high level of thermal comfort.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Primary air volume flow rate</td>
                    <td className='td-2'>8 – 36 l/s [30 – 130 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Cooling capacity (total)</td>
                    <td className='td-2'>max. 1 100 W</td>
                </tr>
                <tr>
                    <td className='td'>Heating capacity</td>
                    <td className='td-2'>max. 960 W</td>
                </tr>
                <tr>
                    <td className='td'>Heating capacity, self-convection</td>
                    <td className='td-2'>245 W</td>
                </tr>
                <tr>
                    <td className='td'>Nominal unit width</td>
                    <td className='td-2'>800, 1 000 and 1 200 mm</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>160 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>455 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low pressure loss (70 to 200 Pa), therefore energy- saving Operation</li>
                <li>Low sound power Level</li>
                <li>Heating also possible without primary air, which saves energy when heating at night and at Weekends</li>
                <li>Heat exchanger cleanable from the front and the rear (in compliance with requirements of VDI 6022). The large fin spacing enables easy cleaning and removes the need for a filter.</li>
                <li>Suitable for new or refurbished Buildings</li>
                <li>Condensate tank below heat exchanger, inclusive of drain with ½“ connector</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Cooling Ceiling System Description</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.5.1.7_IG-M-SB_Induction_unit_for_mixing_ventilation_for_vertical_parapet_mounting_kr_01.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text IG-M-SB</p></div>
            </div>
        </div>
    );
    const productContent78 = (
        <div className="product-content">
            <h3 className='popup_title'>Induction unit for cooling, heating, and fresh air supply along the façade, through window parapets; high level of thermal comfort. Low-turbulence supply air discharge at low momentum according to the principle of displacement ventilation.​​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Primary air volume flow rate</td>
                    <td className='td-2'>8 – 22 l/s [30 – 80 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Cooling capacity (total)</td>
                    <td className='td-2'>max. 500 W</td>
                </tr>
                <tr>
                    <td className='td'>Heating capacity</td>
                    <td className='td-2'>max. 660 W</td>
                </tr>
                <tr>
                    <td className='td'>Width</td>
                    <td className='td-2'>800, 1 000 und 1 200 mm</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>160 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>680 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low pressure loss (70 to 200 Pa), therefore energy- saving Operation</li>
                <li>Low sound power Level</li>
                <li>Heating also possible without primary air, which saves energy when heating at night and at Weekends</li>
                <li>Heat exchanger cleanable from the front and the rear (in compliance with requirements of VDI 6022). The large fin spacing enables easy cleaning and removes the need for a filter.</li>
                <li>Suitable for new or refurbished buildings (for replacing high-pressure induction units)</li>
                <li>Condensate tank below heat exchanger, inclusive of drain with ½” connector</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/D2.5.1.8_IG-Q-SB_Quell-Induktions-Bruestungsgeraet_10.2007-2_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Induction unit for displacement ventilation IG-Q-SB - German Only</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.5.1.8_IG-Q-SB_Induction_unit_for_displacement_ventilation_for_vertical_parapet_mounting_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text IG-Q-SB</p></div>
            </div>
        </div>
    );
    const productContent79 = (
        <div className="product-content">
            <h3 className='popup_title'>Decentralized air conditioning via the building facade.<br />
                To air condition office and administrative buildings having facades with floor-to-ceiling glazing, Krantz supplies ventilation units for raised floor mounting; they provide the required minimum percentage of outdoor air and remove the internal cooling and heating loads. The unit type LG-ZUM-M-LB has an additional function with recirculated air.​​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Supply air volume flow rate</td>
                    <td className='td-2'>33 l/s [120 m3/h]</td>
                </tr>
                <tr>
                    <td className='td'>Supply air temperature, summer</td>
                    <td className='td-2'>18 °C (with outdoor air 32 °C / 40% relative humidity)</td>
                </tr>
                <tr>
                    <td className='td'>Total cooling output: including</td>
                    <td className='td-2'>560 W</td>
                </tr>
                <tr>
                    <td className='td'>– for cooling down 33 l/s [120 m3/h] of outdoor air</td>
                    <td className='td-2'>240 W</td>
                </tr>
                <tr>
                    <td className='td'>– for cooling a room with tR = 26 °C</td>
                    <td className='td-2'>320 W</td>
                </tr>
                <tr>
                    <td className='td'>Chilled water temperature</td>
                    <td className='td-2'>15 / 17 °C</td>
                </tr>
                <tr>
                    <td className='td'>Supply air temperature, winter</td>
                    <td className='td-2'>34 °C (with outdoor air –12 °C)</td>
                </tr>
                <tr>
                    <td className='td'>Total heating Output: including</td>
                    <td className='td-2'>1 840 W</td>
                </tr>
                <tr>
                    <td className='td'>– for heating up 33 l/s [120 m3/h]
                        tR = 22 °C of outdoor air</td>
                    <td className='td-2'>1 360 W</td>
                </tr>
                <tr>
                    <td className='td'>– for room heating with tR = 22 °C</td>
                    <td className='td-2'>480 W</td>
                </tr>
                <tr>
                    <td className='td'>Hot water temperature</td>
                    <td className='td-2'>50 / 40 °C</td>
                </tr>
                <tr>
                    <td className='td'>Control signal for fan speed</td>
                    <td className='td-2'>0 – 10 V DC</td>
                </tr>
                <tr>
                    <td className='td'>Effective power of fan</td>
                    <td className='td-2'>20 W (for volume flow rate = 33 l/s [120 m³/h])</td>
                </tr>
                <tr>
                    <td className='td'>Operating voltage</td>
                    <td className='td-2'>230 V / 50 Hz</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Outdoor air intake directly through the facade; return air extraction via central system</li>
                <li>Cooling and heating via air-to-water heat exchanger</li>
                <li>Cooling with outdoor air (free cooling)</li>
                <li>Mixing & displacement ventilation close to facade</li>
                <li>Energy-efficient EC radial-flow fan</li>
                <li>F7 class filtration of outdoor air</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/D2.5.2.1_LG-Z-M-LB_LG-ZUM-M-LB_Dezentrales_Lueftungsgeraet_fuer_Mischlueftung-liegend_03.2014_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection LG-Z-M-LB (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/DAT2.5.2.1_LG-Z-M-LB_LG-ZUM-M-LB_Dezentrales_Lueftungsgeraet_fuer_Mischlueftung-liegend_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text LG-Z-M-LB (in German only)</p></div>
            </div>
        </div>
    );
    const productContent80 = (
        <div className="product-content">
            <h3 className='popup_title'>To ventilate office and administrative buildings that have façades with floo-to-ceiling glazing, Krantz supplies a ventilation unit work- ing with recirculated air and designed for raised floor mounting; this compact unit with high output is able to remove the internal cooling and heating loads resulting from the large glazed area.​​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Air volume flow rate</td>
                    <td className='td-2'>55 – 97 l/s [200 – 350 m3/h] (continuous Control)</td>
                </tr>
                <tr>
                    <td className='td'>Cooling output<br />Supply air temperature<br />Chilled water temperature</td>
                    <td className='td-2'>700 W<br />18.5 °C<br />15 / 17 °C</td>
                </tr>
                <tr>
                    <td className='td'>Heating output<br />Supply air temperature<br />Hot water temperature</td>
                    <td className='td-2'>1 515 W<br />35 °C<br />50 / 40 °C</td>
                </tr>
                <tr>
                    <td className='td'>Control voltage for fan speed</td>
                    <td className='td-2'>10 V DC</td>
                </tr>
                <tr>
                    <td className='td'>Operating voltage</td>
                    <td className='td-2'>230 V / 50 Hz</td>
                </tr>
                <tr>
                    <td className='td'>Total power requirement (design value)</td>
                    <td className='td-2'>56 VA</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Cooling and heating at the facade; unit mounted in raised floor</li>
                <li>Maximum energy saving thanks to energy-efficient cross-flow fan</li>
                <li>Recirculated air volume flow rate up to 97 l/s [350 m3/h]</li>
                <li>Cooling output up to 700 W</li>
                <li>Low sound power level</li>
                <li>Ideal in conjunction with a floor-mounted supply air ventilation unit providing a minimum percentage of outdoor air</li>
                <li>Feet adjustable in height to make up for vertical constructional tolerances</li>
                <li>Optionally fitted with flexible connection hoses, valves and actuators</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/D2.5.2.2_LG-UM-M-LB_Dezentrales_Lueftungsgeraet_fuer_Mischlueftung-liegend_03.2014-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection LG-UM-M-LB (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/DAT2.5.2.2_LG-UM-M-LB_Dezentrales_Lueftungsgeraet_fuer_Mischlueftung-liegend_kr_NEU.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text (in German only)</p></div>
            </div>
        </div>
    );
    const productContent81 = (
        <div className="product-content">
            <h3 className='popup_title'>Induction unit for cooling, heating, and fresh air supply along the building facade, via a raised floor, providing a high level of thermal comfort.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Primary air volume flow rate</td>
                    <td className='td-2'>4 – 36 l/s [15 – 130 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Cooling capacity (total)</td>
                    <td className='td-2'>max. 1 320 W</td>
                </tr>
                <tr>
                    <td className='td'>Cold water temperature</td>
                    <td className='td-2'>15 / 17 °C</td>
                </tr>
                <tr>
                    <td className='td'>Heating capacity</td>
                    <td className='td-2'>max. 1 200 W</td>
                </tr>
                <tr>
                    <td className='td'>Hot water temperature</td>
                    <td className='td-2'>50 / 40 °C</td>
                </tr>
                <tr>
                    <td className='td'>Heating capacity with self-convection</td>
                    <td className='td-2'>350 W</td>
                </tr>
                <tr>
                    <td className='td'>Nominal unit width</td>
                    <td className='td-2'>1 200 mm (available 800, 900, 1 200, 1 350, 1 600 mm)</td>
                </tr>
                <tr>
                    <td className='td'>Grille depth</td>
                    <td className='td-2'>380 or 500 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height</td>
                    <td className='td-2'>220 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Low pressure loss (70 to 200 Pa), therefore energy-saving operation</li>
                <li>Low sound power level</li>
                <li>Heating also possible without primary air, which saves energy when heating at night and at weekends</li>
                <li>Heat exchanger cleanable from the top or the bottom (in compliance with requirements of VDI 6022). The large fin spacing enables easy cleaning and obviates the need for a filter.</li>
                <li>Suitable for new or refurbished buildings</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection IG-M-LB (in German only)</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EAT2.5.2.3_IG-M-LB_Induction_unit_for_mixing_ventilation_for_horizontal_floor_mounting_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text IG-M-LB</p></div>
            </div>
        </div>
    );
    const productContent82 = (
        <div className="product-content">
            <h3 className='popup_title'>Krantz HEPA filter systems, type SCF hightec Triple S, are used in bio-safety laboratories class 3 and 4.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal air flow HEPA filter element (filter class H14)</td>
                    <td className='td-2'>up to 420 l/s [3 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Max. Initial pressure drop HEPA filter element (filter class H14)</td>
                    <td className='td-2'>300 Pa</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <p className='td'>The design considers the applicable rules like:</p>
            <ul className='list'>
                <li>"Laboratory biosafety manual" by World Health Organization (WHO)</li>
                <li>"Biosafety in Microbiological and Biomedical Laboratories" (BMBL)</li>
                <li>EN 12 128 "Biotechnology - Laboratories for research, development and analysis containment levels of microbiology laboratories, areas of risk, localities and physical safety requirements"</li>
                <li>Robust filter housing made from stainless steel</li>
                <li>3-seal systems for HEPA filter elements</li>
                <li>Tightness proof frame for HEPA filter elements</li>
                <li>Safe change collar (bag-in / bag-out) for filter elements</li>
                <li>In-situ efficiency measurement with scanner system</li>
                <li>Decontamination ports</li>
                <li>Seismic approval (option)</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.1_scf-hightec-triple-s_hi_kpl_02.2016_kr-2.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Safe Change Filter Housing with Scanner SCF hightec Triple S</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA1.1.1_scf-hightec-triple-s_text_for_tender_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Safe Change Filter Housing with Scanner SCF hightec Triple S</p></div>
            </div>
        </div>
    );
    const productContent83 = (
        <div className="product-content">
            <h3 className='popup_title'>Ventilation systems in areas like laboratories, chemical, pharmaceutical or nuclear industry have to separate toxic particles, radioactive particles and pathogen agents in order to save the environment, the production or the health of the personnel. Filter systems have to meet high demands regarding the safety and efficiency of particle separation. For these applications Krantz developed the Safe Change Filter Housing, Type SCF hightec. The SCF hightec filter housing iscustomizable for almost all sophisticated filtering demands.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal air flow per filter element</td>
                    <td className='td-2'>up to 350 l/s [48 000 m³/h] (higher on request)</td>
                </tr>
                <tr>
                    <td className='td'>Admissible differential pressure</td>
                    <td className='td-2'>± 6 000 Pa (higher on request)</td>
                </tr>
                <tr>
                    <td className='td'>Design temperature</td>
                    <td className='td-2'>90°C</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter housing acc. to DIN 24 496</td>
                    <td className='td-2'>leakage rate &lt; 3 · 10-5  of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter deat acc. DIN 24 496</td>
                    <td className='td-2'>leakage rate &lt; 3 · 10-5  of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <p className='head-para'>Various technical high-quality options to equip the system like e. g.</p>
            <ul className='list'>
                <li>an integrated scanner device for an online efficiency measurement resp. leak testing</li>
                <li>an automatic disinfection device, to be connected gastightly to the filter housing, utilise</li>
                <li>a disinfection of the filter housing and filter media</li>
                <li>a gastight shut-off slide or shut-off damper, which is integrated in the air inlet- and outlet chamber</li>
            </ul>
            <p className='head-para'>complete the offer of this product.</p>
            <p className='title'>More features</p>
            <ul className='list'>
                <li>Compact construction</li>
                <li>Modular design according to a modular kit principle</li>
                <li>Many different outfitting possibilities</li>
            </ul>
            <p className='head-para'>The construction based on long time experience regarding</p>
            <ul className='list'>
                <li>applications</li>
                <li>used materials</li>
                <li>tightness requirements</li>
                <li>online efficiency measurements</li>
                <li>decontamination and disinfection</li>
                <li>contamination free exchange of filter elements</li>
                <li>testing and inspection</li>
            </ul>
            <p className='head-para'>The construction is characterised of compact design and many different outfitting. Because of a modular design</p>
            <ul className='list'>
                <li>only one side needed for operation</li>
                <li>the free choice of the arrangement vertical resp. horizontal</li>
                <li>different air inlet- and outlet chambers</li>
            </ul>
            <p className='head-para'>the filter housing is predestined for special requirements and locations with less space.</p>

            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Safe Change Filter Housing with Scanner SCF hightec</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA1.1.2_scf-hightec_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Safe Change Filter Housing with Scanner SCF hightec</p></div>
            </div>
        </div>
    );
    const productContent84 = (
        <div className="product-content">
            <h3 className='popup_title'>Ventilation systems in areas like laboratories, chemical, pharmaceutical or nuclear industry have to separate toxic particles, radioactive particles and pathogen agents in order to save the environment, the production or the health of the personnel. Filter systems have to meet high demands regarding the safety and efficiency of particle separation. For these applications Krantz developed the Safe Change Filter Housing, type SCF classic.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal air flow per filter element</td>
                    <td className='td-2'>up ro 6 675 l/s [24 000 m³/h] (higher on request)</td>
                </tr>
                <tr>
                    <td className='td'>Admissible differential pressure</td>
                    <td className='td-2'>± 6 000 Pa (higher on request)</td>
                </tr>
                <tr>
                    <td className='td'>Design temperature</td>
                    <td className='td-2'>90 °C</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter housing acc. to DIN 24 496</td>
                    <td className='td-2'>leckage rate &lt; 3 · 10-5  of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter seat acc. to DIN 24 496</td>
                    <td className='td-2'>leckage rate &lt; 3 · 10-5  of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Compact stainless steel housing</li>
                <li>Clamping of the HEPA filter elements by means of self-adjusting spring system</li>
                <li>Test groove for each filter element</li>
                <li>Special collar for the safe change technology</li>
            </ul>

            <p className='title'>Various technical high-quality options to equip the system like e. g.</p>
            <ul className='list'>
                <li>an automatic disinfection device, to be connected gastightly to the filter housing, utilize a disinfection of the filter housing and filter media</li>
                <li>a gastight shut-off damper, which is integrated in the air inlet- and outlet chamber</li>
            </ul>
            <p className='head-para'>complete the offer of this product.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.1.3_scf-classic_07.2013_kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Safe Change Filter Housing SCF classic</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA1.1.3_scf-classic_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Safe Change Filter Housing SCF classic</p></div>
            </div>
        </div>
    );
    const productContent85 = (
        <div className="product-content">
            <h3 className='popup_title'>The HEPA filter system, type GS is used to take in HEPA filter elements. It is a less expensive alternative to the famous and reliable Krantz safe change filter housings type SCF classic and SCF hightec. It is used where the requirements concerning the concerning the construction of the filter housing are less.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Pre- filter - Dimensions of filter elements</td>
                    <td className='td-2'>610 x 610 x 50 mm or<br />610 x 610 x 150 mm</td>
                </tr>
                <tr>
                    <td className='td'>Hight of housing</td>
                    <td className='td-2'>348 mm, 443 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal air flow per housing</td>
                    <td className='td-2'>500 l/s [1 800 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Max. air flow per housing 1</td>
                    <td className='td-2'>600 l/s [2 200 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Weight without filter elements</td>
                    <td className='td-2'>approx. 20 kg or approx. 24 kg</td>
                </tr>
                <tr>
                    <td className='td'>HEPA filter- Dimensions of filter elements</td>
                    <td className='td-2'>610 x 610 x 150 mm or<br />
                        610 x 610 x 292</td>
                </tr>
                <tr>
                    <td className='td'>Hight of housing</td>
                    <td className='td-2'>473 mm, 615 mm</td>
                </tr>
                <tr>
                    <td className='td'>Nominal air flow per housing</td>
                    <td className='td-2'>280 l/s [100 m³/h] or 500 l/s [1 800m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Max. air flow per housing 1</td>
                    <td className='td-2'>350 l/s [1 200 m³/h] or 600 l/s [2 200 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Weight without filter elements</td>
                    <td className='td-2'>350 l/s [1 200 m³/h] or 600 l/s [2 200 m³/h]approx. 26 kg or approx. 32 kg</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The filter system can be used single or in combination with additional housings e.g. for pre-filter or sorption filter. For filtration of higher air flows some filter housings can be combined to large filter systems of any size.</li>
                <li>The connection is realized by a gastight duct system at both the top and bottom side of the housing. This allows the installation of one or more filter housings in circular duct systems one after the other.</li>
                <li>Both direction of airflow – „top-down“ or „bottom-up” can be chosen.</li>
                <li>Installation resp. fixing is done by either:</li>
                <ul>
                    <li>plugging on static fixed ducts</li>
                    <li>attaching to wall brackets (available on request)</li>
                    <li>positioning on floor supports (available on request)</li>
                </ul>
                <li>The duct connection is designed as a machine-lip flange for pull-ring connection, e. g. Jacob system, DN 300.</li>
                <li>Also a welded flange according EN 12 220, DN 300, is available as an option.</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - HEPA Filter System GS-Box</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA1.2_gs-box_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - HEPA Filter System GS-Box</p></div>
            </div>
        </div>
    );
    const productContent86 = (
        <div className="product-content">
            <h3 className='popup_title'>Mobile HEPA filter systems, manufactured by Krantz, combine pre-filter and HEPA filter stages with a fan and the control as well. They will be delivered ready-to-use and will be utilized temporarily i.e. when the operational HVAC system is under maintenan.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions</td>
                    <td className='td-2'>MFU classic: app. 915 x 1 820 x 1 000 (800) mm<br />
                        MFU hightec: app. 915 x 1 820 x 1 000 (800) mm</td>
                </tr>
                <tr>
                    <td className='td'>Weight</td>
                    <td className='td-2'>app. 300 kg</td>
                </tr>
                <tr>
                    <td className='td'>Nominal air flow</td>
                    <td className='td-2'>840 l/s [3 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Admissibal differential pressure</td>
                    <td className='td-2'>± 6 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Admissibal design temperature</td>
                    <td className='td-2'>70 °C</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter housing acc. to
                        DIN 24 496</td>
                    <td className='td-2'>Leckage rate es &il; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter seat acc. to
                        DIN 24 496</td>
                    <td className='td-2'>Leckage rate es &il; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Filter element Dimensions W x H x D</td>
                    <td className='td-2'></td>
                </tr>
                <tr>
                    <td className='td'>Fine dust filterelement
                        - Filter class F6 acc. to EN 779</td>
                    <td className='td-2'>MFU classic: 610 x 610 x 78<br />
                        MFU hightec: 610 x 610 x 292 mm</td>
                </tr>
                <tr>
                    <td className='td'>HEPA-Filterelement
                        – Filter class H13 acc. to EN 1822</td>
                    <td className='td-2'>610 x 610 x 292 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Air intake and outlet block</li>
                <li>Robust filter housing</li>
                <li>Gastight circular dampers (raw gas and clean gas side)</li>
                <li>Fan block</li>
                <li>Silencer block</li>
                <li>Device control</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.3.1_mfu_classic_1x1-f6_h13_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Mobile Filter Systems MFU classic</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.3.2_mfu_hightec_1x1-f6_h13_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Mobile Filter Systems MFU hightec</p></div>
            </div>
        </div>
    );
    const productContent87 = (
        <div className="product-content">
            <h3 className='popup_title'>Re-cleanable HEPA filter systems will be used for demanding requirements on the filter separation performance (HEPA), high particle concentrations and abrasive dusts.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions</td>
                    <td className='td-2'>1 600 x 1 900 x 790 mm</td>
                </tr>
                <tr>
                    <td className='td'>Air flow</td>
                    <td className='td-2'>420 l/s [1 500 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Admissible Operation pressure</td>
                    <td className='td-2'>± 6 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature</td>
                    <td className='td-2'>90°C</td>
                </tr>
                <tr>
                    <td className='td'>Compressed air cosumption per recleaning</td>
                    <td className='td-2'>approx. 0.4 Nm³/filter element</td>
                </tr>
                <tr>
                    <td className='td'>Recleaning time per filter element</td>
                    <td className='td-2'>approx. 6 s</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter housing acc. to DIN 24 496</td>
                    <td className='td-2'>Lekage rate &lt; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter seat acc. to DIN 24 496</td>
                    <td className='td-2'>Lekage rate &lt; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
            </table>
            <p className='title'>Your benefits:</p>
            <ul className='list'>
                <li>Optional possibility of recycling the purified dusts</li>
                <li>Low disposal costs</li>
                <li>No filter element exchange necessary</li>
            </ul>

            <p className='title'>Examples of industrial uses:</p>
            <ul className='list'>
                <li>Teardown of nuclear systems</li>
                <li>Pharmaceutical production processes</li>
                <li>Battery manufacture</li>
            </ul>

            <p className='title'>More features:</p>
            <ul className='list'>
                <li>Top-down principle</li>
                <li>Lower depths of filter elements for critical dust</li>
                <li>Very high cleaning pressure (up to 4 bar overpressure in nozzle bar)</li>
                <li>Patented, reinforced RHF ® standard size elements</li>
                <li>Pressure loss reduction through cleaning process, i. e.</li>
                <ul>
                    <li>Lower and more constant initial pressure drop after cleaning</li>
                    <li>Higher working pressure drop in filter possible</li>
                    <li>Formation of a filter “cake” on the surface of the filter medium</li>
                    <li>Good separation of agglomerated dust in the hopper</li>
                    <li>Suitable for different kinds of dust, especially for critical particles (rough, sticky, etc.)</li>
                </ul>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Mobile Re-cleanable Filter Systems RHF hightec</p></div>
            </div>
        </div>
    );
    const productContent88 = (
        <div className="product-content">
            <h3 className='popup_title'>Re-cleanable HEPA filter systems will be used for demanding requirements on the filter separation performance (HEPA), high particle concentrations and abrasive dusts.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Air flow (adjustable)</td>
                    <td className='td-2'>840 l/s [3 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Admissible differential pressure</td>
                    <td className='td-2'>± 7 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature</td>
                    <td className='td-2'>80°C</td>
                </tr>
                <tr>
                    <td className='td'>Necessary upstream pressure</td>
                    <td className='td-2'>6 bar continuous pressure</td>
                </tr>
                <tr>
                    <td className='td'>Compressed air quality</td>
                    <td className='td-2'>oil- and water - free, dew Point -5 °C</td>
                </tr>
                <tr>
                    <td className='td'>Conection flange raw gass side</td>
                    <td className='td-2'>DN 350 (Jakob system)</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter housing acc. to DIN 24 496</td>
                    <td className='td-2'>leakage rate &lt; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter seat acc. to DIN 24 496</td>
                    <td className='td-2'>leakage rate &lt; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa </td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Both blocks form independent units and can be transported independently</li>
                <li>For simple room air filtering, M-SCF-V alone can be used. For this block, an extensive range of special accessories is available on request, including additional HEPA filter stages, active carbon filter stages, and plug-in sound damper units.</li>
                <li>To ensure easy decontamination, welded seams on components in contact with the media are free from any gaps and continuously welded.</li>
                <li>The clamping mechanisms for HEPA filter elements can be operated from outside</li>
                <li>Contamination-free filter exchange (bag-in-bag-out)</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Re-cleanable Filter Systems M-SCF classic</p></div>
            </div>
        </div>
    );
    const productContent89 = (
        <div className="product-content">
            <h3 className='popup_title'>HEPA filter air outlet with pull-down outlet element, for air cleanliness levels of Class 6 to 8 to EN ISO 14644-1 and Grade C and D to EU GMP. Fields of application for the HEPA filter air outlets are all branches of industry, research and medicine as well as hospital areas where air cleanliness is demanded according to the above-mentioned Levels.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range<br /> – standard filter housing<br /> – rectangular filter housing</td>
                    <td className='td-2'>69 – 236 l/s [250 – 850 m³/h]<br />
                        222 – 389 l/s [800 – 1 400 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Cross-section housing<br />– standard filter housing<br />– rectangular filter housing</td>
                    <td className='td-2'>570 x 570 mm<br />1 195 x 570 mm</td>
                </tr>
                <tr>
                    <td className='td'>Height of housing<br />
                        – connection housing with lateral rectangular connection spigot<br />
                        – connection housing with lateral round connection spigot</td>
                    <td className='td-2'>391 mm<br /><br />432 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Suited for cleanliness levels 6 to 8 to EN ISO 14644-1, and C and D to EU GMP</li>
                <li>The installation situation with pull-down outlet element saves time during the implementation of filter scan tests (first test and retests)</li>
                <li>No additional sealing of the air outlets after the fitting or the replacement of the filter</li>
                <li>Modular design – one standard housing for the volume flow rate ranges 69 to 236 l/s [250 to 850 m³/h] (V1 to V4)</li>
                <li>Designs for HEPA filter air outlet with dry sealing (with or without test groove) or fluid sealing</li>
                <li>Discreet integration into the suspended ceiling by square visible surface</li>
                <li>Stable radial flow with strong induction of supply air and room air at the PDK and the PCK</li>
                <li>Build-up of a very uniform supply air cushion below the air outlet; consequently only minor or no accumulation of dirt on the ceiling with the PCK</li>
                <li>Connections for</li>
                <ul>
                    <li>differential pressure measurement</li>
                    <li>leakage test, optional</li>
                    <li>test aerosol, optional</li>
                </ul>
                <li>Recovery test according to EN ISO 14644-3 with short recovery times, attested by qualification records</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Puri-Drall PDK, Puri-Clean PCK and Puri-Inlet PEK</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.5.2_puri-drall-pdk_puri-clean-pck_puri-inlet-pek_01.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Puri-Drall PDK, Puri-Clean PCK and Puri-Inlet PEK</p></div>
            </div>
        </div>
    );
    const productContent90 = (
        <div className="product-content">
            <h3 className='popup_title'>For adsorption of high risk pollutants, which need a long dwell time, Krantz sorption filter systems type CFH classic or CFH hightec will be used.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal air flow</td>
                    <td className='td-2'>up to &gt; 8 400 l/s [30 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Adimissible design differential pressure</td>
                    <td className='td-2'>up to 10 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter housing acc. to DIN 24 496</td>
                    <td className='td-2'>leakage rate &lt; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Dwell time at nominal air flow</td>
                    <td className='td-2'>0.25 - 2.50 s</td>
                </tr>
                <tr>
                    <td className='td'>Air flow velocity</td>
                    <td className='td-2'>&lt; 0.5 m/s</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Easy handling of the safe change technology</li>
                <li>Only filter housing and carbon, no changing element required</li>
                <li>Compact and versatile design</li>
                <li>Simple, efficient and clean refill procedure</li>
                <li>High protection of environment and operational staff</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>While with the filter system CFH classic 100% of the loaded carbon will be changed at one time, the load capacity of the carbon will be used more efficient with the filter system CFH hightec. There, only the first half of the filter bed will be disposed, while the relatively unloaded second half of the filter bed will be changed to the first half. Only the second half of the filter bed will be filled with new carbon. Any adsorption filter system type CFH will be designed according the special requirements of the single Project.</p>
        </div>
    );
    const productContent108 = (
        <div className="product-content">
            <h3 className='popup_title'>For adsorption of high risk pollutants, which need a long dwell time, Krantz sorption filter systems type CFH classic or CFH hightecwill be used.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal air flow</td>
                    <td className='td-2'>up to &gt; 5 500 l/s [20 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Adimissible differential pressure</td>
                    <td className='td-2'>up to 10 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Tightness of filter housing acc. to DIN 24 496</td>
                    <td className='td-2'>leakage rate &lt; 3 · 10-5 of nominal air flow at Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Dwell time at nominal air flow</td>
                    <td className='td-2'>0.25 - 2.50 s</td>
                </tr>
                <tr>
                    <td className='td'>Air flow velocity</td>
                    <td className='td-2'>&lt; 0.5 m/s</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Easy handling of the safe change technology</li>
                <li>Only filter housing and carbon, no changing element required</li>
                <li>Compact and versatile design</li>
                <li>Simple, efficient and clean refill procedure</li>
                <li>High protection of environment and operational staff</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>While with the filter system CFH classic 100% of the loaded carbon will be changed at one time, the load capacity of the carbon will be used more efficient with the filter system CFH hightec. There, only the first half of the filter bed will be disposed, while the relatively unloaded second half of the filter bed will be changed to the first half. Only the second half of the filter bed will be filled with new carbon.<br />
                Any adsorption filter system type CFH will be designed according the special requirements of the single project.</p>
        </div>
    );
    const productContent109 = (
        <div className="product-content">
            <h3 className='popup_title'>For the separation of airborne particles, aerosols and olfactory pestering material in the nuclear industry, research, industry and so on, Krantz delivers a sorption filter cell for changing.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Air flow</td>
                    <td className='td-2'>max. 420 l/s [1 500 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Section area</td>
                    <td className='td-2'>1.66 m³</td>
                </tr>
                <tr>
                    <td className='td'>Depth of filter bed</td>
                    <td className='td-2'>50 mm</td>
                </tr>
                <tr>
                    <td className='td'>Pressure drop</td>
                    <td className='td-2'>Depents of used Carbon media</td>
                </tr>
                <tr>
                    <td className='td'>Volume of filling</td>
                    <td className='td-2'>approx. 75 l</td>
                </tr>
                <tr>
                    <td className='td'>Weight without filling</td>
                    <td className='td-2'>approx. 36 kg</td>
                </tr>
                <tr>
                    <td className='td'>Filter cell size W x L x D</td>
                    <td className='td-2'>610 mm x 610 mm x 292 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The dimensions of the filter cell are 610 mm x 610 mm x 292 mm</li>
                <li>Large inflow area</li>
                <li>Surrounding sealing</li>
                <li>Handles for easy use</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>For this type only the filter material and the sealing will be changed, while the housing could be further used. The using of this type instead of disposal filter cells, reduces the waste considerably. You do something for pollution control.<br />
                The filter element could be filled with any kind of sorption material. The choice of the right sorption material is the precondition for attainment of high separation efficiency.
            </p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E1.8_wfz_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Sorption Filter Element WFZ</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA1.8_wfz_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Sorption Filter Element WFZ</p></div>
            </div>
        </div>
    );
    const productContent91 = (
        <div className="product-content">
            <h3 className='popup_title'>The gastight circular shut-off damper GD-C in solid and maintenancefree design, is used for systems with high tightness requirements.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions</td>
                    <td className='td-2'>DN 150, DN 200, DN 250, DN 360, DN 400</td>
                </tr>
                <tr>
                    <td className='td'>Actuator</td>
                    <td className='td-2'>electrical, pneumatic, manual, or emergency actuating by means of Hand wheel</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature</td>
                    <td className='td-2'>up to +100 °C</td>
                </tr>
                <tr>
                    <td className='td'>Admissible operating pressure</td>
                    <td className='td-2'>10 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate damper blade incl. seat of damper clade acc. DIN 25 496</td>
                    <td className='td-2'>&lt; 10 l/(h ⋅ m²) at 1 bar, 20 °C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate housing incl. shaft transition acc. DIN 25 496</td>
                    <td className='td-2'>&lt; 10 l/(h ⋅ m²) at 1 bar, 20 °C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Material<br />
                        Robust damper housing<br /><br /><br />

                        Circular damper blade<br /><br />

                        Gas-tight shaft transition through housing</td>
                    <td className='td-2'>stainless steel, material 1.4301 (AISI/SAE 304), in gastight design<br />
                        stainless steel, material 1.4301 (AISI/SAE 304)<br />
                        shaft sealing made of viton</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The actual leakage rates for housings and seat of damper blade are much lower than specified in the requirement according to DIN 25 496</li>
                <li>The damper is designed to operate without any failure at an operating pressure of 1.1- times of the admissible operating pressure of the damper</li>
                <li>The tightness of seat of damper blade is testable in built-in situation</li>
                <li>For maintenance purposes, e. g. lubricating, if required, there is no dismantling of the damper from the duct system necessary</li>
                <li>All media touched parts are welded continuously and without gaps to ensure an easy decontamination</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Circular Damper GD-C</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.1.1_gd-c_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Circular Damper GD-C</p></div>
            </div>
        </div>
    );
    const productContent92 = (
        <div className="product-content">
            <h3 className='popup_title'>The gastight rectangular shut-off damper GD-R will be used in ventilation systems for sensitive areas, like the chemical, the pharmaceutical, the nuclear industry or bio-safety laboratories, where high leak tightness requirements apply.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions<br /><br />
                        - Rectangular design<br /><br />
                        - Square design</td>
                    <td className='td-2'><br /><br />max. 1 800 mm x 1 200 mm<br />from 400 mm x 400 mm up to<br />1 200 mm x 1 200 mm</td>
                </tr>
                <tr>
                    <td className='td'>Actuator</td>
                    <td className='td-2'>electrical, pneumatic, manual, or electrical actuator also available with spring return (fail-safe)</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature for damper</td>
                    <td className='td-2'>- 40 °C to + 100 °C</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature for actuator</td>
                    <td className='td-2'>- 5 °C to + 70 °C</td>
                </tr>
                <tr>
                    <td className='td'>Admissible operation pressure</td>
                    <td className='td-2'>10 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. pressure difference for damper blade in closing direction</td>
                    <td className='td-2'>10 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate damper blade incl. seat of damper blade acc. DIN 25 496</td>
                    <td className='td-2'>&lt;10 l/(h·m²) at 1 bar, 20 °C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate housing incl. shaft transition acc. DIN 25 496</td>
                    <td className='td-2'>&lt;10 l/(h·m²) at 1 bar, 20 °C and Δp = 2 000 Pa</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The specially designed kinematical mechanism secures the damper blade at the end positions “open” and “close” by means of special toggle levers in case of a failure of energy support.</li>
                <li>Two concentric sealing rings fixed to the damper seat create high tightness as well as a test groove in the gap between the two sealing rings. In order to test the leak free seat of the damper blade the test groove can be connected to an appropriate seal test device via a fast acting coupling from outside the damper.</li>
                <li>To protect the sealing the damper blade starts the opening process with a linear movement. This movement is created by a special lever system, so first the damper runs parallel and after reaching a certain distance the movement turns automatically into a rotating motion. This mechanism avoids transverse loading force to the damper sealing.</li>
                <li>Double shaft transition sealing. Connections to test tightness of shaft transition during operation available on demand.</li>
                <li>Housing, damper blade and lever mechanism made of stainless steel.</li>
                <li>All media-touched parts are welded continuously and without gaps to ensure an easy decontamination.</li>
                <li>The housing, the damper seat and the shaft transition surpass by far the leak tightness requirements of DIN 25 496.</li>
                <li>Electrical, pneumatic and manual actuators available. The electrical actuator is also available as spring return (fail-safe) version.</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>The gastight rectangular shut-off damper GD-R surpasses by far the leak tightness requirements specified in DIN 25 496 (Ventilating components in nuclear facilities).<br />
                Krantz‘s gastight rectangular shut-off damper GD-R guarantees a maximum of safety and reliability thanks to its design, its system and the special damper mechanism.
            </p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.1.2_gd-r_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Gastight Rectangular Damper GD-R</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.1.2_gd-r_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Gastight Rectangular Damper GD-R</p></div>
            </div>
        </div>
    );
    const productContent93 = (
        <div className="product-content">
            <h3 className='popup_title'>Krantz has developed the gastight, rectangular shut-off damper, type S, for use in ventilating systems with high requirements in regard to tightness, e. g. in nuclear facilities, laboratories, etc.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Admissible operation pressure for housing</td>
                    <td className='td-2'>± 10 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Admissible differential pressure for damper blade in both directions</td>
                    <td className='td-2'>± 10 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature for damper</td>
                    <td className='td-2'>-20°C up to +120°C</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate damper blade incl. seat of damper blade acc. DIN 25 496</td>
                    <td className='td-2'>&lt;10 l/(h·m²) at 1 bar, 20 °C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate housing incl. shaft transition acc. DIN 25 496</td>
                    <td className='td-2'>&lt;10 l/(h·m²) at 1 bar, 20 °C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Medium supply, compressed air, oil free and waterless</td>
                    <td className='td-2'>positive pressure 3 bar</td>
                </tr>
                <tr>
                    <td className='td'>Current</td>
                    <td className='td-2'>230 V, 50 Hz</td>
                </tr>
                <tr>
                    <td className='td'>Position indication “open” and “close”<br />
                        Mechanical<br />
                        Electrical</td>
                    <td className='td-2'><br /><br />position indicator<br />
                        2 limit switches</td>
                </tr>
                <tr>
                    <td className='td'>Actuator</td>
                    <td className='td-2'>pneumatic</td>
                </tr>
                <tr>
                    <td className='td'>Regulation time</td>
                    <td className='td-2'>30 s, longer times adjustable</td>
                </tr>
                <tr>
                    <td className='td'>Material<br />
                        Housing and damper blade<br />
                        Damper sealing</td>
                    <td className='td-2'><br /><br />stainless steel<br />Silicone</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The shut-off damper consists of a damper housing, a hinged damper blade with the damper shaft as its bearings, and an actuating cylinder moving the damper blade by an actuating equipment.</li>
                <li>A sealing profile made of silicone rubber is fastened to the damper blade by a leading bar. Upon closure of the damper, this sealing profile is pressed against the damper housing by means of an inflatable silicon tube which produces the sealing effect.</li>
                <li>The sealing profile provided with two double lips allows the tight seat of the damper blade on the mounted shut-off damper to be checked.</li>
                <li>The damper blade and the damper housing are made of stainless steel, the tube leading bars and profiles consists of aluminum. The control unit is placed on the head of the actuating cylinder. The shut-off damper is actuated from a customer provided switchboard cabinet.</li>
                <li>The media supplies needed by the control unit are electric energy as well as compressed air, 3 bar overpressure, oil free and waterless.</li>
                <li>The shut-off damper is actuated by means of a double acting pneumatic cylinder. The control unit ensures the proper sequence of motions including tube inflation and venting, respectively.</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>The requirements on tightness as laid down in the German standard DIN 25 496 – Ventilating components in nuclear facilities - are more than satisfied.<br />
                Several decades of experience gathered in the operation of shut-off damper systems in nuclear facilities and labs have decisively influenced the design and shape.<br />
                Quite a number of references from various locations of shut-off damper installation have proved the high level of perfection attained by this.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Rectangular Damper S</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.1.3_s-klappe_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Rectangular Damper S</p></div>
            </div>
        </div>
    );
    const productContent94 = (
        <div className="product-content">
            <h3 className='popup_title'>In a great variety of different applications within HVAC systems pressure relief dampers manufactured by Krantz are used to limit the pressure and control it, respectively. Typical applications include the protection of ductwork from inadmissible high loading caused by positive pressure or negative pressure, positive pressure ventilation of staircases, or setting a defined pressure drop between adjacent rooms.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions W x H x D</td>
                    <td className='td-2'>800 x 500 x 620 mm</td>
                </tr>
                <tr>
                    <td className='td'>Weight</td>
                    <td className='td-2'>36 kg</td>
                </tr>
                <tr>
                    <td className='td'>Range of air flow<sup>1</sup></td>
                    <td className='td-2'>4 725 l/s [17 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Opening pressure, adjustable</td>
                    <td className='td-2'>1 100 - 2 300 Pa</td>
                </tr>
            </table>
            <p className='head-para mt-1'>1 Higher range of volume flow achievable by parallel connection of several dampers</p>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Airtight according to EN 1751, class 4 in closed position opens when opening pressure set is attained</li>
                <li>Almost equal differential pressure independent of range of volume flow (control function)</li>
                <li>Closes automatically when opening pressure is underrated</li>
                <li>Setting of opening pressure by variation of lever arm</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>The Pressure Relief Damper KL-E is a design variant provided with control function which, as soon as then opening pressure is exceeded, keeps nearly constant the pressure in the ductwork, independent of the range of volume flow. When the pressure in the ductwork reduces to its normal range after the malfunction has been remedied, the damper closes automatically.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.2.1_kl-e-klappe-07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>2.2.1. Technical Selection - Pressure Relief Damper KL-E</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.2.1-2_kl-e_em_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Pressure Relief Damper KL-E and KL-EM</p></div>
            </div>
        </div>
    );
    const productContent95 = (
        <div className="product-content">
            <h3 className='popup_title'>In a great variety of different applications within HVAC systems pressure relief dampers manufactured by Krantz are used to limit the pressure and control it, respectively. Typical applications include the protection of ductwork from inadmissible high loading caused by positive pressure or negative pressure, positive pressure ventilation of staircases, or setting a defined pressure drop between adjacent rooms.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions W x H x D</td>
                    <td className='td-2'>630 x 400 x 510 mm</td>
                </tr>
                <tr>
                    <td className='td'>Weight</td>
                    <td className='td-2'>23 kg</td>
                </tr>
                <tr>
                    <td className='td'>Range of air flow<sup>1</sup></td>
                    <td className='td-2'>Vmin= 1 400 l/s [5 000 m³/h]<br />

                        Vmax= 125  √Opening pressure [Pa] l/s<br />

                        [Vmax= 450 √Opening pressure [Pa] m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Opening pressure, adjustable</td>
                    <td className='td-2'>700 - 2 400 Pa</td>
                </tr>
            </table>
            <p className='head-para mt-1'>1 Higher range flow achivable by parallel Connection of several dampers</p>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Airtight according to EN 1751, class 4 in closed position opens fully when opening pressure set is attained (switching function)</li>
                <li>Closes automatically upon interruption of flow</li>
                <li>Setting of opening pressure by variation of lever arm</li>
                <li>Closing of damper after exceeding of opening pressure by hand</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>The Pressure Relief Damper KL-EM features a switching function. Independent of the range of volume flow of the outlet flow the damper blade gets fully opened when the opening pressure is exceeded. When the pressure in the ductwork falls below the opening pressure after the malfunction has been remedied, the damper normally remains open. In this state of operation the damper KL-EM must be closed manually, whereas it closes automatically when the flow is interrupted, e. g. by disabling of the fan. Despite its smaller outside dimensions type KL-EM is capable of removing flows at higher range of volume flow as compared with KL-E.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>2.2.2. Technical Selection - Pressure Relief Damper KL-EM</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.2.1-2_kl-e_em_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Pressure Relief Damper KL-EM</p></div>
            </div>
        </div>
    );
    const productContent96 = (
        <div className="product-content">
            <h3 className='popup_title'>In a great variety of different applications within HVAC systems pressure relief dampers manufactured by Krantz are used to limit the pressure and control it, respectively. Typical applications include the protection of ductwork from inadmissible high loading caused by positive pressure or negative pressure, positive pressure ventilation of staircases, or setting a defined pressure drop between adjacent rooms.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions W x H x D</td>
                    <td className='td-2'>350 x 600 x 485 mm</td>
                </tr>
                <tr>
                    <td className='td'>Weight</td>
                    <td className='td-2'>approx. 19 kg</td>
                </tr>
                <tr>
                    <td className='td'>Range of air flow<sup>1</sup></td>
                    <td className='td-2'>1 400 l/s [5 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Opening pressure, adjustable</td>
                    <td className='td-2'>50 - 150 Pa <sup>2</sup></td>
                </tr>
            </table>
            <p className='head-para mt-1'>1 Higher range flow achivable by parallel Connection of several dampers</p>
            <p>2 Higher Opening pressure on request</p>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Airtight according to EN 1751, class 4 in closed Position</li>
                <li>Opens when opening pressure set is attained</li>
                <li>Almost equal differential pressure independent of range of volume flow (control function)</li>
                <li>Closes automatically when opening pressure is underrated</li>
                <li>Setting of opening pressure by variation of restoring mass</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>It is often required that a defined pressure gradient of 50 − 150 Pa is set between two neighboring rooms. This guarantees a directed flow of air in the leakage ports existing between the rooms and thus avoids the undesired transport of pollutants into the room to be protected. Pressure relief dampers KL-ETE can be used in this application both as control device for setting a defined pressure gradient between two rooms and as safety valve to set a limit to a maximum admissible pressure value. Depending on available space and range of volume flow to be relieved pressure relief damper KL-ETE or KL-ETM is the optimal choice.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.2.3_kl-ete_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Pressure Relief Damper KL-ETE</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.2.3-4_kl-ete_etm_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Pressure Relief Damper KL-ETE</p></div>
            </div>
        </div>
    );
    const productContent97 = (
        <div className="product-content">
            <h3 className='popup_title'>In a great variety of different applications within HVAC systems pressure relief dampers manufactured by Krantz are used to limit the pressure and control it, respectively. Typical applications include the protection of ductwork from inadmissible high loading caused by positive pressure or negative pressure, positive pressure ventilation of staircases, or setting a defined pressure drop between adjacent rooms.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions W x H x D</td>
                    <td className='td-2'>250 x 150 x 250 mm</td>
                </tr>
                <tr>
                    <td className='td'>Weight</td>
                    <td className='td-2'>approx. 3.5 kg</td>
                </tr>
                <tr>
                    <td className='td'>Range of air flow<sup>1</sup></td>
                    <td className='td-2'>84 l/s [300 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Opening pressure, adjustable</td>
                    <td className='td-2'>50 - 80 Pa <sup>2</sup></td>
                </tr>
            </table>
            <p className='head-para mt-1'>1 Higher range flow achivable by parallel Connection of several dampers</p>
            <p className='head-para'>2 Higher Opening pressure on request</p>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Opens when opening pressure set is attained</li>
                <li>Almost equal differential pressure independent of range of volume flow (control function)</li>
                <li>Closes automatically when opening pressure is underrated</li>
                <li>Setting of opening pressure by variation of restoring mass</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>It is often required that a defined pressure gradient of 50 − 150 Pa is set between two neighboring rooms. This guarantees a directed flow of air in the leakage ports existing between the rooms and thus avoids the undesired transport of pollutants into the room to be protected. Pressure relief dampers type KL-ETE can be used in this application both as control device for setting a defined pressure gradient between two rooms and as safety valve to set a limit to a maximum admissible pressure value. Depending on available space and range of volume flow to be relieved pressure relief damper type KL-ETE or type KL-ETM is the optimal choice.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>2.2.4. Technical Selection - Pressure Relief Damper KL-ETM</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.2.3-4_kl-ete_etm_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Pressure Relief Damper KL-ETM</p></div>
            </div>
        </div>
    );
    const productContent98 = (
        <div className="product-content">
            <h3 className='popup_title'>Louver damper, type LD-J and type ND-J, will be used for controlling air flow and shutting off air ducts.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal dimensions (W/H)</td>
                    <td className='td-2'>see table</td>
                </tr>
                <tr>
                    <td className='td'>Max. temperature</td>
                    <td className='td-2'>90°C</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate damper housing</td>
                    <td className='td-2'>class C acc. to DIN EN 1751</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate damper blades (lamellas)</td>
                    <td className='td-2'>class 4 acc. to DIN EN 1751</td>
                </tr>
                <tr>
                    <td className='td'>Frame</td>
                    <td className='td-2'>Stainless steel or galvanised steel, 1.5 mm, by means of a screwed construction</td>
                </tr>
                <tr>
                    <td className='td'>Actuator</td>
                    <td className='td-2'>manual, eletrical, or pneumatic</td>
                </tr>
                <tr>
                    <td className='td'>Nominal dimensions in mm</td>
                    <td className='td-2'></td>
                </tr>
                <tr>
                    <td className='td'>B 1</td>
                    <td className='td-2'>400 	500 	600 	700 	800 	900 	1000 	1200 	1400 	1600 	1800 	2000 	 ---</td>
                </tr>
                <tr>
                    <td className='td'>H 1</td>
                    <td className='td-2'>150	300	450	600	750	900	1050	1200	1350	1500	1650	1800	1950</td>
                </tr>
            </table>
            <p className='head-para mt-1'>1 All combinations of W and H dimensions possible, other dimensions also possible</p>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The damper is designed to operate without any failure at an operating pressure of 1.1 time the admissible operating pressure</li>
                <li>Warp-resistant hollow body lamellas made of aluminum (optional with coated or eloxiered aluminium)</li>
                <li>Lamellas equipped with changeable silicone rubber Profile</li>
                <li>Counter rotating lamellas by means of external positioned rod, parallel rotating lamellas available on request</li>
                <li>Maintenance-free Operation</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>It is often required that a defined pressure gradient of 50 − 150 Pa is set between two neighboring rooms. This guarantees a directed flow of air in the leakage ports existing between the rooms and thus avoids the undesired transport of pollutants into the room to be protected. Pressure relief dampers type KL-ETE can be used in this application both as control device for setting a defined pressure gradient between two rooms and as safety valve to set a limit to a maximum admissible pressure value. Depending on available space and range of volume flow to be relieved pressure relief damper type KL-ETE or type KL-ETM is the optimal choice.</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Airtight Louver Damper LD-J</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.3.1_ld-j_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Airtight Louver Damper LD-J</p></div>
            </div>
        </div>
    );
    const productContent99 = (
        <div className="product-content">
            <h3 className='popup_title'>Louver damper LD-J and ND-J will be used for controlling air flow and shutting off air ducts.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Nominal dimensions (W/H)</td>
                    <td className='td-2'>see table</td>
                </tr>
                <tr>
                    <td className='td'>Max. temperature</td>
                    <td className='td-2'>90°C</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate damper housing DIN 25 496</td>
                    <td className='td-2'>&lt;10 l/(h·m<sup>2</sup>) at 20°C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate damper blades (lamellas)</td>
                    <td className='td-2'>class 4 acc. to DIN EN 1751</td>
                </tr>
                <tr>
                    <td className='td'>Frame</td>
                    <td className='td-2'>Stainless steel 2 mm by means of a screwed construction</td>
                </tr>
                <tr>
                    <td className='td'>Actuator</td>
                    <td className='td-2'>Manual, eletrical, or pneumatic</td>
                </tr>
                <tr>
                    <td className='td'>Nominal dimensions in mm</td>
                    <td className='td-2'></td>
                </tr>
                <tr>
                    <td className='td'>B 1</td>
                    <td className='td-2'>400 	500 	600 	700 	800 	900 	1000 	1200 	1400 	1600 	1800 	2000 	 ---</td>
                </tr>
                <tr>
                    <td className='td'>H 1</td>
                    <td className='td-2'>150	300	450	600	750	900	1050	1200	1350	1500	1650	1800	1950</td>
                </tr>
            </table>
            <p className='head-para mt-1'>1 All combinations of W and H dimensions possible, other dimensions also possible</p>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The damper is designed to operate without any failure at an operating pressure of 1.1 time the admissible operating pressure</li>
                <li>Warp-resistant hollow body lamellas made of aluminum, 150 mm</li>
                <li>Lamellas equipped with changeable silicon rubber profile</li>
                <li>Counterrotating lamellas by means of external positioned rod. parallel rotating lamellas available on request</li>
                <li>Electrical-, pneumatic-, and manual actuator available</li>
                <li>Maintenance-free operation</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.3.2_nd-j_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Louver Damper ND-J</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.3.2_nd-j_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Louver Damper ND-J</p></div>
            </div>
        </div>
    );
    const productContent100 = (
        <div className="product-content">
            <h3 className='popup_title'>Self acting non return dampers will close at reverse airflows in HVAC systems and will lock ducts or air handling units automatically.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions</td>
                    <td className='td-2'>from 200 mm x 200 mm to 1 400 mm x 2 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Total depth of damper</td>
                    <td className='td-2'>360 mm</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature</td>
                    <td className='td-2'>90°C</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate of damper housing acc. DIN 25 496<br />
                        (outer leak tightness)</td>
                    <td className='td-2'>&lt;10 l/(h·m2) at 1 bar, 20°C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Adm. leakage rate of damper blade acc. DIN 25 496 (inner leak tightness)</td>
                    <td className='td-2'>2 % of nominal air flow rate at 1 bar, 20 °C and Δp = 2 000 Pa</td>
                </tr>
                <tr>
                    <td className='td'>Material<br />
                        Damper housing / separating plates<br />
                        Reinforcing angle and back plate<br />
                        Lamellae</td>
                    <td className='td-2'>galvanised steel or stainless steel<br />
                        aluminium<br />
                        silicone</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The robust damper housing made from optional stainless or galvanized steel can contain up to seven lamellae.</li>
                <li>Non return dampers with more than one lamella additionally have crossbars made from U-profile, welded to the damper housing. These cross-bars are serving as fixing for the lamellae and as supporting surface for the sealing.</li>
                <li>The lamellae are made from ageing resistant reinforced elastic material with a non-breakable inlet. A solid reinforcement and stabilization of the damper blade sealing is done by aluminum reinforcement angles on incoming flow side of the damper and aluminum plates on the back side.</li>
                <li>The elastic lamellae are heat resistible up to 90 °C. For higher requirements lamellae with higher heat resistance are available as an option (additional price).</li>
                <li>The flow-separating plates at back side of damper housing are creating single ducts behind each lamella to avoid transmission of impulse between partial air flows.</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>They are installed e. g. often on the positive pressure side of parallel arranged ventilators and will prevent reverse airflows at a stop of one ventilator. When the ventilators are running the lamellae are open due to the airflow energy.<br />
                Non return dampers are optimal if the pressure drop should be low with open lamellae and the leakage should be very small in closed position. Krantz is producing non return dampers since many years and these have been installed in HVAC systems with good success. Based on practical experience and many laboratory tests Krantz non return dampers have been optimized. The result is a non return damper with a very low pressure drop and especially small leakage rates.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.4_rk-e20_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection -Non-return Damper RK-E20</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.4_rk-e20_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Non-return Damper RK-E20</p></div>
            </div>
        </div>
    );
    const productContent101 = (
        <div className="product-content">
            <h3 className='popup_title'>In case of a requirement that single duct legs or wall openings has to be open in normal operation and to be closed in a pressure wave (produced by chemical reactions, gas-explosions or mechanical damages at pressure vessels) resp. a pressure increasing situation, Krantz recommend to use of their pressure surge damper.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Dimensions</td>
                    <td className='td-2'>from 200 mm x 500 mm to 1 250 mm x 2 000 mm</td>
                </tr>
                <tr>
                    <td className='td'>Total depth of damper</td>
                    <td className='td-2'>360 mm</td>
                </tr>
                <tr>
                    <td className='td'>Admissible design temperature</td>
                    <td className='td-2'>100°C</td>
                </tr>
                <tr>
                    <td className='td'>Pressure blast</td>
                    <td className='td-2'>0.45 bar</td>
                </tr>
                <tr>
                    <td className='td'>Material Housing,<br />
                        blades and damper rods</td>
                    <td className='td-2'>made of special steel 1.4571</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The pressure wave can reach in about 100 milliseconds a positive pressure of approx. 0.45 bar and their duration will take about 1 s</li>
                <li>Pressure surge damper of sturdy design, dimensioned for shock waves up to 0.5 bar. The function remains unreduced after a shock wave.</li>
                <li>The pressure surge damper requires no accessory energy</li>
                <li>Damper housing with connection frame on both sides</li>
                <li>Built-in inclined blades with axis and bearings</li>
                <li>Damper rods on both sides with spring tensioning appliance</li>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>According to the closing pressure an initial tension will be adjusted at an installed spring system. This initial tension ensures an open position of the damper regarding wind blasts and variation of pressure inside the duct system. In open position and case of installation outside of outer walls the damper also fulfils the functions of a rain protection grille.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E2.5_rk-f10_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Pressure Surge Damper RK-F10</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA2.5_rk-f10_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - Pressure Surge Damper RK-F10</p></div>
            </div>
        </div>
    );
    const productContent102 = (
        <div className="product-content">
            <h3 className='popup_title'>Krantz HEPA-filter elements are classified H13 or H14 resp., acc. DIN EN 1822. They are designed to handle higher airflow than the corresponding filter elements of other companies. The increase airflow capacity of the Krantz filter elements is the result, more media.​</h3>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>For new installations fewer filter elements are required, the result is that less space is required for the new installation place</li>
                <li>For installations which are already equipped:</li>
                <ul>
                    <li>Lower resistance, lower energy cost, and substantially longer life</li>
                    <li>Tapered separators provides a high retention capacity</li>
                </ul>
                <li>Result of the high retention capacity, the lifetime of the filter elements extends</li>
                <li>Factory testing for each filter element – your assurance that it meets the rated efficiency</li>
                <li>Easy installation</li>
            </ul>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E3.1.1_hepa_h13_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - HEPA-Filter H13</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/E3.1.2_hepa_h14_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - HEPA-Filter H14</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - special HEPA Filter H14</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA3.1.1-2_hepa-filter_h13_h14_h14-sonder_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender text - HEPA-Filter H13, H14 and special H14</p></div>
            </div>
        </div>
    );
    const productContent103 = (
        <div className="product-content">
            <h3 className='popup_title'>Return air inlet for filtering textile fibres out of the return air of clean rooms, particularly operating theatres and ancillary rooms; for wall or duct mounting.​</h3>
            <p className='title'>Data</p>
            <p className='title'>Standard construction</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range	</td>
                    <td className='td-2'>65 - 1 670 l/s [230 - 6 000 m3/h]</td>
                </tr>
                <tr>
                    <td className='td'>Dimensions W x H</td>
                    <td className='td-2'>225 mm x 225 mm to
                        825 mm x 825 mm</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>61 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Fibre retention on inward wire-mesh filter</li>
                <li>Filter easily removable by hand, without tools, for maintenance and disinfection purposes</li>
                <li>Mounting in smooth room or duct walls.</li>
                <li>Standard fibre filter dimensions same as those of customary return air grills</li>
                <li>Available in many sizes</li>
                <li>Material: stainless steel, material No. 1.4301</li>
                <li>Well suited for refitting existing HVAC systems in clean rooms</li>
                <li>Low sound power Level</li>
                <li>Optional available with slotted sliding device for air flow adjustment</li>
            </ul>
        </div>
    );
    const productContent104 = (
        <div className="product-content">
            <h3 className='popup_title'>Return air inlet for filtering textile fibres out of the return air of clean rooms, particularly operating theatres and ancillary rooms; for wall or duct mounting.​</h3>
            <p className='title'>Data</p>
            <table>
                <tr>
                    <td className='td'>Volume flow rate range	</td>
                    <td className='td-2'>65 – 1 670 l/s [230 – 6 000 m³/h]</td>
                </tr>
                <tr>
                    <td className='td'>Width x height</td>
                    <td className='td-2'>225 mm x 225 mm<br />
                        to 825 mm x 825 mm</td>
                </tr>
                <tr>
                    <td className='td'>Depth</td>
                    <td className='td-2'>75 mm</td>
                </tr>
            </table>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Fibre retention on inward wire-mesh filter</li>
                <li>Filter easily removable by hand, without tools, pull-down for maintenance and disinfection purposes</li>
                <li>Mounting in smooth room or duct walls. Standard fibre filter dimensions same as those of customary return air grills</li>
                <li>Available in many size</li>
                <li>Material: stainless steel, material No. 1.4301</li>
                <li>Well suited for refitting existing HVAC systems in clean rooms</li>
                <li>Low sound power level</li>
                <li>Optional available with slotted sliding device for air flow adjustment</li>
            </ul>
        </div>
    );
    const productContent105 = (
        <div className="product-content">
            <h3 className='popup_title'>In order to guarantee the performance of components and systems in nuclear facilities, laboratories etc. during operation Krantz manufactures a portable leak testing device.​</h3>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>The leakage air flow is determined following the constant pressure method, which means the housing or the test groove of the seal resp. is filled with air until the predetermined test pressure is reached. The feed in air flow which is necessary to keep the test pressure constant is equivalent to the leakage air flow.</li>
                <li>Two measuring ranges are available:</li>
                <ul>
                    <li>For small leakage air flow: 0.01 – 0.15 l/min (test range I)</li>
                    <li>For higher leakage air flow: 0.15 – 1.5 l/min (test range II)</li>
                </ul>
                <li>The feed in to reach the predetermined test pressure as well as holding the test pressure constant is done by means of a hand pump</li>
                <li>Enclosed to each portable seal testing device:</li>
                <ul>
                    <li>Manual</li>
                    <li>Certificates of calibration of the measuring devices</li>
                    <li>Hand pump</li>
                    <li>Connecting hose with 4 mm inner diameter and coupling nipples on both sides</li>
                    <li>Fast acting coupling</li>
                </ul>
            </ul>
            <p className='title'>Information</p>
            <p className='list'>The portable leak testing device type LTD is for testing of the permissible leakage air flow e. g. according to DIN 25 496 “HVAC components in nuclear facilities” or KTA 3601 “HVAC systems in nuclear power plants” for:</p>
            <ul className='list'>
                <li>The tight seat of filter elements</li>
                <li>The tight seat of damper blades</li>
                <li>The tightness of housings</li>
            </ul>
            <p className='list'>in a measuring range from 0.01 to 1.5 l/min up to a theoretical test pressure of 5 000 Pa. The measuring devices integrated in the housing will be calibrated before assembly.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="/E3.3_lt-d_07.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Leak Test Device LT-D</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA3.3_ltd_08.2013-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Leak Test Device LT-D</p></div>
            </div>
        </div>
    );
    const productContent106 = (
        <div className="product-content">
            <h3 className='popup_title'>Dehumidifiers are intended to meet industrial and commercial needs for dry air. The heart of the unit is the high moisture absorbing capacity rotor.​</h3>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Volume flow rate range 80 - 2220 l/s [290 – 8 000 m³/h]</li>
                <li>Compact design and low weight of the unit High performance at low temperatures and low relative humidity in a room</li>
                <li>Easy access to the internal components of the dehumidifier for the maintenance</li>
                <li>PTC-heater</li>
                <li>High efficiency of the washable silica gel rotor</li>
                <li>The design facilitates easy transport</li>
                All components made exclusively by European manufacturers
            </ul>
            <p className='title'>Information</p>
            <p className='list'>Our desiccant dehumidifiers are well proven and used in the following areas:</p>
            <ul className='list'>
                <li>Food industry (confectionery, meat processing, dairy, beer production)</li>
                <li>Drug manufacturing (tablets, ampoules, drug storage)</li>
                <li>Chemical industry (mineral fertilizer storage, transport)</li>
                <li>Sports facilities (ice arenas)</li>
                <li>Energy (equipment preservation with dry air)</li>
                <li>Products storage with close control of the humidity in the air</li>
            </ul>
            <p className='list'>Our Desiccant dehumidifiers will help you to solve the problems that arise due to increased moisture in the air which are corrosion, product damage, product adhesion, mold and fungi growth on the surfaces.</p>
            <p className='title'>Service & Downloads</p>
            <div className="row">
                <div className='col-md-1'><Link to="https://www.krantz.de/en/" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Technical Selection - Desiccant Dehumidifier MDC</p></div>
            </div>
            <div className="row">
                <div className='col-md-1'><Link to="/EA3.8_desiccant-dehumidifier-mdc_2015-kr.pdf" target='blank'><img src={viewfile} alt="view_file" className="popup_icon" /></Link></div>
                <div className='col-md-10 pdf_para'><p>Tender Text - Desiccant Dehumidifier MDC</p></div>
            </div>
        </div>
    );
    const productContent107 = (
        <div className="product-content">
            <h3 className='popup_title'>Additionally to its range of filter systems and dampers Krantz offers gastight doors for use in bio-safety laboratories (BSL), pharmaceutical industry and nuclear facilities.​</h3>
            <p className='title'>Features</p>
            <ul className='list'>
                <li>Doors in firm and bending resistant design, provided for rooms with high requirements concerning tightness</li>
                <li>Material stainless steel or mild steel with special coating for decontamination</li>
                <li>In compliance with the national standard “General Regulation of Bio-safety in Laboratories”</li>
                <li>Inspection window from toughened glass sealed in a stainless steel frame (option)</li>
                <li>Hydraulic door closer (option)</li>
            </ul>
        </div>
    );


    const products = [
        { img: product1, title: '1.1. TWIST OUTLET DD-N', content: productContent1 },
        { img: product2, title: '1.2.1. RADIAL SLOT OUTLET RL-Q2 / RL-R2', content: productContent2 },
        { img: product3, title: '1.2.2. RADIAL SLOT OUTLET RL-C', content: productContent3 },
        { img: product4, title: '1.3.1. RADIAL OUTLET RA-N', content: productContent4 },
        { img: product5, title: '1.3.2. RADIAL OUTLET RA-N2', content: productContent5 },
        { img: product6, title: '1.3.3. RADIAL OUTLET RA-N3', content: productContent6 },
        { img: product7, title: '1.4.1. ADJUSTABLE RADIAL OUTLET RA-V', content: productContent7 },
        { img: product8, title: '1.4.2. ADJUSTABLE RADIAL OUTLET WITH CORE TUBE RA-V2', content: productContent8 },
        { img: product9, title: '1.5. MICRODRALL MD', content: productContent9 },
        { img: product10, title: '1.6.2. VARIABLE TWIST OUTLET WITH CORE TUBE DD-VK', content: productContent10 },
        { img: product11, title: '1.6.3. VARIABLE TWIST OUTLET WITH GUIDE RING DD-VL', content: productContent11 },
        { img: product12, title: '1.6.4. VARIABLE TWIST OUTLET WITH JET STRAIGHTENER DD-VG', content: productContent12 },
        { img: product13, title: '1.8. INDUCTION OUTLET WITH PRESET DISCHARGE DIRECTION IN-N6', content: productContent13 },
        { img: product14, title: '1.9. ADJUSTABLE INDUCTION OUTLET IN-V', content: productContent14 },
        { img: product15, title: '1.10.1. OPTICLEAN OC-Q', content: productContent15 },
        { img: product16, title: '1.10.2. CIRCULAR OPTICLEAN OC-R', content: productContent16 },
        { img: product17, title: '2.1.1. MULTIPLEX OUTLET FA-VT', content: productContent17 },
        { img: product18, title: '2.1.2. COMBINED MULTIPLEX OUTLET FA-VT-K', content: productContent18 },
        { img: product19, title: '2.2. LINEAR WHIRL OUTLET WL', content: productContent19 },
        { img: product20, title: '2.3.1. SWIVEL JET NOZZLE DW-V2', content: productContent20 },
        { img: product21, title: '2.3.2. TWIST NOZZLE DW-V2-DR', content: productContent21 },
        { img: product22, title: '2.3.3. JET NOZZLE DW-N2', content: productContent22 },
        { img: product23, title: '2.4. SWIVEL JET OUTLET SW', content: productContent23 },
        { img: product24, title: '2.5. WINDOW AIR CURTAIN UNIT FSG', content: productContent24 },
        { img: product25, title: '2.6. BROAD MULTIPLEX OUTLET BF-V', content: productContent25 },
        { img: product26, title: '2.7. PARAPET OUTLET BL-V', content: productContent26 },
        { img: product27, title: '2.8. WALL SLOT DIFFUSER WSD', content: productContent27 },
        { img: product28, title: '2.9.1. AND 2.9.3. CROSSTALK ATTENUATION AIR TRANSFER ELEMENT OG-S, OG-T AND OG-U', content: productContent28 },
        { img: product29, title: '2.9.2 ACTIVE AIR TRANSFER ELEMENT A-SAVE WITH CROSSTALK ATTENUATOR', content: productContent29 },
        { img: product30, title: '3.1. FLOOR TWIST OUTLET DB-E', content: productContent30 },
        { img: product31, title: '3.2. ROTARY FLOOR TWIST OUTLET DB-D', content: productContent31 },
        { img: product32, title: '3.3. ADJUSTABLE FLOOR OUTLET BA-V-DN 150', content: productContent32 },
        { img: product33, title: '3.4. NTK FLOOR TWIST OUTLET DB-N-DN 215', content: productContent33 },
        { img: product34, title: '3.6. FLOOR DISPLACEMENT OUTLET Q-B-DN 200', content: productContent34 },
        { img: product35, title: '3.7. FLOOR DISPLACEMENT OUTLET Q-B-DN 215', content: productContent35 },
        { img: product36, title: '4.1. RECTANGULAR DISPLACEMENT OUTLET Q-R', content: productContent36 },
        { img: product37, title: '4.2.1. CIRCULAR DISPLACEMENT OUTLET Q-Z', content: productContent37 },
        { img: product38, title: '4.2.2. SEMI-CIRCULAR DISPLACEMENT OUTLET Q-ZH', content: productContent38 },
        { img: product39, title: '4.5. WALL DISPLACEMENT OUTLET Q-WL, Q-WR AND Q-WK', content: productContent39 },
        { img: product40, title: '4.6. CEILING DISPLACEMENT OUTLET Q-DN AND Q-DV', content: productContent40 },
        { img: product41, title: '5.2.4. RECTANGULAR FLOOR DISPLACEMENT OUTLET Q-BR', content: productContent41 },
        { img: product42, title: '5.1. CIRCULAR DISPLACEMENT OUTLET VA-ZD', content: productContent42 },
        { img: product43, title: '5.2. RECTANGULAR DISPLACEMENT OUTLET VA-RV / VA-RN', content: productContent43 },
        { img: product44, title: '5.3.1. TRAPEZOIDAL DISPLACEMENT OUTLET VA-T', content: productContent44 },
        { img: product45, title: '5.3.2. SEMI-TRAPEZOIDAL DISPLACEMENT OUTLET VA-TH', content: productContent45 },
        { img: product46, title: '5.4. SWIVEL DISPLACEMENT OUTLET VA-S', content: productContent46 },
        { img: product47, title: '5.5. RADIAL DISPLACEMENT OUTLET VA-PV', content: productContent47 },
        { img: product48, title: '5.6. CONICAL DISPLACEMENT OUTLET VA-K', content: productContent48 },
        { img: product49, title: '5.7. LAMINAR OUTLET VA-L', content: productContent49 },
        { img: product50, title: '5.8. DISPLACEMENT VENTILATION FOR INDOOR FIRING RANGES VA-RSA', content: productContent50 },
        { img: product51, title: '6.2. SEAT DISPLACEMENT OUTLET Q-ST', content: productContent51 },
        { img: product52, title: '6.3. STEP DISPLACEMENT OUTLET Q-SR AND Q-SL', content: productContent52 },
        { img: product53, title: '6.4.1. STEP TWIST OUTLET DS', content: productContent53 },
        { img: product54, title: '6.4.2. STEP TWIST OUTLET DS-RA-DN 80', content: productContent54 },
        { img: product55, title: '6.4.3. LINEAR STEP TWIST OUTLET SD-L', content: productContent55 },
        { img: product56, title: '7.1. CIRCULAR VOLUME FLOW CONTROLLER VRI-DN', content: productContent56 },
        { img: product57, title: '8.1 VENT AND DRAIN CAPS', content: productContent57 },
        { img: product58, title: '1.1.1. STATIC COOLING CEILING SYSTEM SKS-4/3, FOR CONCEALED INSTALLATION', content: productContent58 },
        { img: product59, title: '1.1.2. STATIC COOLING CEILING SYSTEM SKS-4/3-DUO, FOR CONCEALED INSTALLATION', content: productContent59 },
        { img: product60, title: '1.2. STATIC COOLING CEILING SYSTEM SKS-5/3, FOR VISIBLE INSTALLATION', content: productContent60 },
        { img: product61, title: '2.1.1. CONTACT COOLING CEILING SYSTEM KKS-3/LD, FOR METAL CEILINGS', content: productContent61 },
        { img: product62, title: '2.1.2 CONTACT COOLING CEILING SYSTEM KKS-5/LD-M, FOR METAL CEILINGS', content: productContent62 },
        { img: product63, title: '2.2. CONTACT COOLING CEILING SYSTEM KKS-4/GK, FOR GYPSUM PLASTERBOARD', content: productContent63 },
        { img: product64, title: '2.3. PLASTER COOLING CEILING SYSTEM PKS', content: productContent64 },
        { img: product65, title: '3.1.1. MULTIFUNCTION EXPOXED CEILING AS RADIATION COOLING AND HEATING PANEL', content: productContent65 },
        { img: product66, title: '3.1.2. MULTIFUNCTION EXPODED CEILING AVACS, SUPPLY AIR', content: productContent66 },
        { img: product67, title: '3.1.3. MULTIFUNCTION EXPOSED CEILING AVACS', content: productContent67 },
        { img: product68, title: '3.1.4. MULTIFUNCTION EXPOSED CEILING KRANTZCOOL', content: productContent68 },
        { img: product69, title: '3.1.5. CHILLED SAILS MADE WITH HIGH-CAPACITY CEILING ELEMENTS', content: productContent69 },
        { img: product70, title: '3.2. CHILLED SAILS MADE WITH GYPSUM BOARD', content: productContent70 },
        { img: product71, title: '4.1. CHILLED BEAM DK-F, WITHOUT VENTILATION FUNCTION', content: productContent71 },
        { img: product72, title: '4.2.1. CHILLED BEAM DK-LIG/Z, WITH VENTILATION FUNCTION, TWO-SIDED DISCHARGE', content: productContent72 },
        { img: product73, title: '4.2.2. CHILLED BEAM DK-LIG/E, WITH VENTILATION FUNCTION, ONE-SIDED DISCHARGE', content: productContent73 },
        { img: product74, title: '4.5. CHILLED BEAM DK-LIG-EW', content: productContent74 },
        { img: product75, title: '4.5.2. CHILLED BEAM DK-LIO-TH', content: productContent75 },
        { img: product76, title: '5.1.3. VENTILATION UNIT (SUPPLY/RETURN AIR) FOR MIXING VENTILATION LG-ZA-M-SB, FOR VERTICAL PARAPET MOUNTING', content: productContent76 },
        { img: product77, title: '5.1.7. INDUCTION UNIT FOR MIXING VENTILATION IG-M-SB, FOR VERTICAL PARAPET MOUNTING', content: productContent77 },
        { img: product78, title: '5.1.8. INDUCTION UNIT FOR DISPLACEMENT VENTILATION IG-Q-SB, FOR VERTICAL PARAPET MOUNTING', content: productContent78 },
        { img: product79, title: '5.2.1. VENTILATION UNITS FOR MIXING VENTILATION LG-Z-M-LB AND LG-ZUM-M-LB, FOR HORIZONTAL FLOOR MOUNTING', content: productContent79 },
        { img: product80, title: '5.2.2. VENTILATION UNIT (RECIRCULATED AIR) FOR MIXING VENTILATION LG-UM-M-LB, FOR HORIZONTAL FLOOR MOUNTING', content: productContent80 },
        { img: product81, title: '5.2.3. INDUCTION UNIT FOR MIXING VENTILATION IG-M-LB, FOR HORIZONTAL FLOOR MOUNTING', content: productContent81 },
        { img: product82, title: '1.1.1. SAFE CHANGE FILTER HOUSING WITH SCANNER SCF HIGHTEC TRIPLE S', content: productContent82 },
        { img: product83, title: '1.1.2. SAFE CHANGE FILTER HOUSING WITH SCANNER SCF HIGHTEC', content: productContent83 },
        { img: product84, title: '1.1.3. SAFE CHANGE FILTER HOUSING SCF CLASSIC', content: productContent84 },
        { img: product85, title: '1.2. HEPA FILTER SYSTEM GS-BOX', content: productContent85 },
        { img: product86, title: '1.3.1. AND 1.3.2. MOBILE FILTER SYSTEMS MFU CLASSIC AND MFU HIGHTEC', content: productContent86 },
        { img: product87, title: '1.4.1. MOBILE RE-CLEANABLE FILTER SYSTEMS RHF HIGHTEC', content: productContent87 },
        { img: product88, title: '1.4.2. RE-CLEANABLE FILTER SYSTEMS M-SCF CLASSIC', content: productContent88 },
        { img: product89, title: '1.5.2. PURI-DRALL PDK, PURI-CLEAN PCK, PURI-INLET PEK', content: productContent89 },
        { img: product90, title: '1.6.1. ADSORPTIONSFILTER CFH CLASSIC', content: productContent90 },
        { img: product108, title: '1.6.2. ADSORPTIONSFILTER CFH HIGHTEC', content: productContent108 },
        { img: product109, title: '1.8. SORPTION FILTER ELEMENT WFZ', content: productContent109 },
        { img: product91, title: '2.1.1. GASTIGHT CIRCULAR DAMPER GD-C', content: productContent91 },
        { img: product92, title: '2.1.2. GASTIGHT RECTANGULAR DAMPER GD-R', content: productContent92 },
        { img: product93, title: '2.1.3. RECTANGULAR DAMPER S', content: productContent93 },
        { img: product94, title: '2.2.1. PRESSURE RELIEF DAMPER FOR HIGH OPENING PRESSURES, WITH CONTROL FUNCTION KL-E', content: productContent94 },
        { img: product95, title: '2.2.2. PRESSURE RELIEF DAMPERS FOR HIGH OPENING PRESSURES, WITH SWITCHING FUNCTION KL-EM', content: productContent95 },
        { img: product96, title: '2.2.3. PRESSURE RELIEF DAMPERS FOR LOW OPENING PRESSURES AT HIGH RANGE OF VOLUME FLOW KL-ETE', content: productContent96 },
        { img: product97, title: '2.2.4. PRESSURE RELIEF DAMPERS FOR LOW OPENING PRESSURES AT LOW RANGE OF VOLUME FLOW KL-ETM', content: productContent97 },
        { img: product98, title: '2.3.1. LOUVER DAMPER, AIRTIGHT DESIGN LD-J', content: productContent98 },
        { img: product99, title: '2.3.2. LOUVER DAMPER, GASTIGHT DESIGN ND-J', content: productContent99 },
        { img: product100, title: '2.4. NON-RETURN DAMPER RK-E20', content: productContent100 },
        { img: product101, title: '2.5. PRESSURE SURGE DAMPER RK-F10', content: productContent101 },
        { img: product102, title: '3.1.1. AND 3.1.2. HEPA FILTER ELEMENTS H13 AND H14', content: productContent102 },
        { img: product103, title: '3.2.1. FIBRE FILTER F', content: productContent103 },
        { img: product104, title: '3.2.2. FIBRE FILTER G', content: productContent104 },
        { img: product105, title: '3.3. LEAK TEST DEVICE LT-D', content: productContent105 },
        { img: product106, title: '3.8. DESICCANT DEHUMIDIFIER MDC', content: productContent106 },
        { img: product107, title: 'GASTIGHT DOORS GT', content: productContent107 },
    ];


    return (
        <>
            <Helmet>
                <title>HEPA Filters | HEPA Filter housings</title>
                <meta name="description" content=" " />
                <meta name="robots" content="index, follow" />  
                <meta name="geo.placename" content="Dubai" />
                <meta http-equiv="content-language" content="ae" />
                <meta name="geo.region" content="Dubai" />
                <meta property="og:title" content="HEPA Filters | HEPA Filter housings" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="/images/banner_1.1b036fc9c5f12fd2465b.jpg" />
                <meta property="og:locale" content="en-ae" />
                <link rel="alternate" href="https://www.scientechnology.com/air_distribution" hreflang="en-ae" />
                <link rel="canonical" href="https://www.scientechnology.com/air_distribution" />
            </Helmet>
            <section id='air_distribution'>
                <Header />
                <div className='container' style={{ width: "75%", margin: "auto", marginTop: "100px" }}>
                    <div className='row'>
                        <div className='col-md-8 col-sm-12 air-distribution'>
                            <h2 className='air_distribution_title'>Air Distribution Systems</h2>
                            <ProductSection ref={ceillingAirOutletsRef} title="Ceiling Air Outlets" products={products} startIndex={0} endIndex={16} handleImageClick={handleImageClick} />
                            <ProductSection ref={sidewallAirOutletsRef} title="Sidewall Air Outlets" products={products} startIndex={16} endIndex={29} handleImageClick={handleImageClick} />
                            <ProductSection ref={floorAirOutletsRef} title="Floor-air-outlets" products={products} startIndex={29} endIndex={35} handleImageClick={handleImageClick} />
                            <ProductSection ref={displacementOutletsCommercialRef} title="Displacement outlets for the commercial sector" products={products} startIndex={35} endIndex={41} handleImageClick={handleImageClick} />
                            <ProductSection ref={displacementOutletsIndustrialRef} title="Displacement outlets for the industrial sector" products={products} startIndex={41} endIndex={50} handleImageClick={handleImageClick} />
                            <ProductSection ref={airOutletsAssemblyRoomsRef} title="Air outlets for assembly rooms" products={products} startIndex={50} endIndex={55} handleImageClick={handleImageClick} />
                            <ProductSection ref={volumeFlowControllerRef} title="Volume flow controller" products={products} startIndex={55} endIndex={56} handleImageClick={handleImageClick} />
                            <ProductSection ref={otherRef} title="Other" products={products} startIndex={56} endIndex={57} handleImageClick={handleImageClick} />
                            <h2 className='air_distribution_title' id='cooling-heating'>Cooling and Heating Systems</h2>
                            <ProductSection ref={highCapacityCeilingSystemsRef} title="High-capacity Ceiling Systems" products={products} startIndex={57} endIndex={60} handleImageClick={handleImageClick} />
                            <ProductSection ref={metalGypsumBoardCeilingSystemsRef} title="Metal, gypsum board and plaster ceiling systems" products={products} startIndex={60} endIndex={64} handleImageClick={handleImageClick} />
                            <ProductSection ref={chilledSailsRef} title="Chilled Sails" products={products} startIndex={64} endIndex={70} handleImageClick={handleImageClick} />
                            <ProductSection ref={chilledBeamsRef} title="Chilled Beams" products={products} startIndex={70} endIndex={75} handleImageClick={handleImageClick} />
                            <ProductSection ref={facadeFloorMountedSystemsRef} title="Facade- and Floor-mounted systems" products={products} startIndex={75} endIndex={81} handleImageClick={handleImageClick} />
                            <h2 className='air_distribution_title' id='filter-damper'>Filter and Damper Systems</h2>
                            <ProductSection ref={hepaFilterSystemsRef} title="HEPA Filter Systems" products={products} startIndex={81} endIndex={92} handleImageClick={handleImageClick} />
                            <ProductSection ref={dampersRef} title="Dampers" products={products} startIndex={92} endIndex={103} handleImageClick={handleImageClick} />
                            <ProductSection ref={accessoryMiscellaneousRef} title="Accessory and Miscellaneous" products={products} startIndex={103} endIndex={109} handleImageClick={handleImageClick} />
                        </div>
                        <div className='col-md-4 mt-3 airdistribution_accordion'>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            Air Distribution Systems
                                        </button>
                                        <hr />
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(ceillingAirOutletsRef)}>Ceilling Air Outlets</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(sidewallAirOutletsRef)}>Sidewall air outlets</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(floorAirOutletsRef)}>Floor-air-outlets</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(displacementOutletsCommercialRef)}>Displacement outlets for the commercial sector</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(displacementOutletsIndustrialRef)}>Displacement outlets for the industrial sector</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(airOutletsAssemblyRoomsRef)}>Air outlets for assembly rooms</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(volumeFlowControllerRef)}>Volume flow controller</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(otherRef)}>Other</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            Cooling and Heating Systems
                                        </button>
                                        <hr />
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(highCapacityCeilingSystemsRef)}>High-capacity Ceiling Systems</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(metalGypsumBoardCeilingSystemsRef)}>Metal, gypsum board and plaster ceiling systems</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(chilledSailsRef)}>Chilled Sails</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(chilledBeamsRef)}>Chilled Beams</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(facadeFloorMountedSystemsRef)}>Facade- and Floor-mounted systems</Link><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Filter and Damper Systems
                                        </button>
                                        <hr />
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(hepaFilterSystemsRef)}>HEPA Filter Systems</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(dampersRef)}>Dampers</Link><br /><br />
                                            <Link className='accordion-body-link' onClick={() => scrollToRefSection(accessoryMiscellaneousRef)}>Accessory and Miscellaneous</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='hr_bottom' />
                </div>
            </section>
            <AnimatePresence>
                {popupData.isVisible && (
                    <motion.div
                        className='row popup'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        onClick={handleClickOutside}
                    >
                        <motion.div
                            className='popup-content'
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.5 }}
                            {...animations[popupData.animation]}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <span className='close' onClick={handleClosePopup}>&times;</span>
                            <div className='col-md-6 col-12 popup-left'>
                                <img src={popupData.image} alt="Popup" />
                            </div>
                            <div className='col-md-6 col-12 popup-right'>
                                <h4 className='popup_main_title'>{popupData.title}</h4>
                                {popupData.content}
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
            <Footer />
            <ScrollToTopButton />
        </>
    )
}

export default AirDistribution