import React from 'react'

function HighIntegrity() {
  return (
    <>
      {/* <embed src="/pdfs/Corona-Filter.pdf" width="100%" height="2100px" /> */}
      <iframe
        src="/pdfs/Corona-Filter.pdf"
        width="100%"
        height="2100px"
        style={{ border: 'none' }}
        title="Corona Filter PDF"
      />
    </>
  )
}

export default HighIntegrity